import { CatalogObject, ComplexFactory, Translation, Link, User } from 'anketa-core'
import { load as ymlLoad } from 'js-yaml'

export class SecuramCatalog extends CatalogObject {
  private static _instance: SecuramCatalog

  constructor () {
    super('securam', new Translation('de', 'Securam Audit Katalog'))
  }

  public static get instance (): SecuramCatalog {
    if (SecuramCatalog._instance !== undefined) {
      ComplexFactory.registerClass({ name: 'Link', Constructor: Link as new (init?: any) => Link, form: Link.getForm })
      ComplexFactory.registerClass({ name: 'User', Constructor: User as new (init?: any) => User, form: User.getForm })
      return SecuramCatalog._instance
    }
    /* eslint-disable no-useless-escape */
    const src = `id: securam
type: Catalog
title:
- language: de4
  message: Securam Audit
- language: en
  message: Securam Audit
children:
- id: '4'
  type: Section
  title:
  - language: de
    message: 4 Kontext der Organisation
  - language: en
    message: 4 Context of the organization
  children:
  - id: '1'
    type: Group
    title:
    - language: de
      message: 4.1 Verständnis der Organisation und ihres Kontexts
    - language: en
      message: 4.1 Understanding the organization and its context
    children:
    - id: 4-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 4-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 4-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 4-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 4-compliance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      section: h
      structure: 4.1-0
      assessment:
        de: Begutachtung zur Systemförderung
        en: Advancement assessment
    description:
    - language: de
      message: Die Organisation muss ermitteln, welche externen und internen Aspekte für ihren Zweck relevant sind und sich auf ihre Fähigkeit auswirken, die beabsichtigten Ergebnisse ihres Informationssicherheitsmanagementsystems zu erreichen.
    - language: en
      message: The organization shall determine external and internal issues that are relevant to its purpose and that affect its ability to achieve the intended outcome(s) of its information security management system.
  - id: '2'
    type: Group
    title:
    - language: de
      message: 4.2 Berücksichtigung der Bedürfnisse und Erwartungen interessierter Parteien
    - language: en
      message: 4.2 Understanding the needs and expectations of interested parties
    children:
    - id: 6-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 6-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 6-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 6-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 6-compliance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 4.2-0
      assessment:
        de: Nachbegutachtung
        en: Follow-up assessment
    description:
    - language: de
      message: |-
        Die Organisation muss Folgendes ermitteln:
        - a) interessierte Parteien, die im Hinblick auf das Informationssicherheitsmanagementsystem relevant sind; und

        - b) die Forderungen dieser interessierten Parteien in Bezug auf die Informationssicherheit.
    - language: en
      message: |-
        The organization shall determine:
        - a) interested parties that are relevant to the information security management system; and

        - b) the requirements of these interested parties relevant to information security.
  - id: '3'
    type: Group
    title:
    - language: de
      message: 4.3 Festlegung des Geltungsbereichs des Informationssicherheitsmanagementsystems
    - language: en
      message: 4.3 Determining the scope of the information security management system
    children:
    - id: 8-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 8-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 8-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 8-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 8-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 9-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 9-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 9-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 9-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 9-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 10-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 10-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 10-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 10-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 10-compliance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 4.3-0
    description:
    - language: de
      message: Der Geltungsbereich muss als dokumentierte Information verfügbar sein.
    - language: en
      message: The scope shall be available as documented information.
  - id: '4'
    type: Group
    title:
    - language: de
      message: 4.4 Informationssicherheitsmanagementsystem
    - language: en
      message: 4.4 Information security management system
    children:
    - id: 12-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 12-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 12-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 12-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 12-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      section: h
      structure: 4.4-0
    description:
    - language: de
      message: Die Organisation muss ein Informationssicherheitsmanagementsystem gemäß den Anforderungen dieser Internationalen Norm einrichten, implementieren, aufrechterhalten und laufend verbessern.
    - language: en
      message: The organization shall establish, implement, maintain and continually improve an information security management system, in accordance with the requirements of this International Standard.
  attributes:
    type: Group
    changes: c
    section: H1
    structure: 4-0
    assessment:
      de: Systembegutachtung
      en: Initial assessment
  sectionType: Volume
- id: '5'
  type: Section
  title:
  - language: de
    message: 5 Führung
  - language: en
    message: 5 Leadership
  children:
  - id: '1'
    type: Group
    title:
    - language: de
      message: 5.1 Führung und Engagement
    - language: en
      message: 5.1 Leadership and commitment
    children:
    - id: 15-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 15-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 15-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 15-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 15-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 5.1-0
    description:
    - language: de
      message: |-
        Die Leitung muss durch folgende Maßnahmen ihre Führung und ihr Engagement in Bezug auf das Informationssicherheitsmanagementsystem demonstrieren:
        - a) Sicherstellung, dass eine Informationssicherheitspolitik und Informationssicherheitsziele vorgegeben werden und mit der strategischen Ausrichtung der Organisation vereinbar sind;

        - b) Sicherstellung, dass die Anforderungen im Rahmen des Informationssicherheitsmanagementsystem in die Prozesse der Organisation integriert werden;

        - c) Sicherstellung, dass die für das Informationssicherheitsmanagementsystem erforderlichen Ressourcen bereitstehen;

        - d) Kommunikation der Bedeutung eines effektiven Informationssicherheitsmanagements sowie der Einhaltung der Anforderungen im Rahmen des Informationssicherheitsmanagementsystems;

        - e) Sicherstellung, dass mit dem Informationssicherheitsmanagementsystem die beabsichtigten Ergebnisse erzielt werden;

        - f) Führung und Unterstützung von Personen, damit diese einen Beitrag zur Wirksamkeit des Informationssicherheitsmanagementsystems leisten;

        - g) Förderung der laufenden Verbesserung; und

        - h) Unterstützung anderer relevanter Führungskräfte bei der Demonstration ihrer Führung im jeweiligen Zuständigkeitsbereich.
    - language: en
      message: |-
        Top management shall demonstrate leadership and commitment with respect to the information security management system by:
        - a) ensuring the information security policy and the information security objectives are established and are compatible with the strategic direction of the organization;

        - b) ensuring the integration of the information security management system requirements into the organization's processes;

        - c) ensuring that the resources needed for the information security management system are available;

        - d) communicating the importance of effective information security management and of conforming to the information security management system requirements;

        - e) ensuring that the information security management system achieves its intended outcome(s);

        - f) directing and supporting persons to contribute to the effectiveness of the information security management system;

        - g) promoting continual improvement; and) supporting other relevant management roles to demonstrate their leadership as it applies to their areas of responsibility.
  - id: '2'
    type: Group
    title:
    - language: de
      message: 5.2 Politik
    - language: en
      message: 5.2 Policy
    children:
    - id: 17-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 17-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 17-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 17-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 17-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 18-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 18-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 18-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 18-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 18-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: c
      section: h
      structure: 5.2-0
    description:
    - language: de
      message: |-
        Die Informationssicherheitspolitik muss:

        - e) als dokumentierte Information verfügbar sein;

        - f) innerhalb der Organisation bekannt gemacht werden; und

        - g) ggf. interessierten Parteien zur Verfügung stehen.
    - language: en
      message: |-
        The information security policy shall:

        - e) be available as documented information;

        - f) be communicated within the organization; and

        - g) be available to interested parties, as appropriate.
  - id: '3'
    type: Group
    title:
    - language: de
      message: 5.3 Organisatorische Rollen, Zuständigkeiten und Befugnisse
    - language: en
      message: 5.3 Organizational roles, responsibilities and authorities
    children:
    - id: 20-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 20-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 20-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 20-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 20-surveilance-compendium
      type: Question
      questionType: audit.compendium
    - id: 21-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 21-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 21-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 21-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 21-compliance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      section: h
      structure: 5.3-0
    description:
    - language: de
      message: |-
        Die Leitung muss Zuständigkeiten und Befugnisse für folgende Aspekte zuweisen:
        - a) Sicherstellung, dass das Informationssicherheitsmanagementsystem den Anforderungen dieser Internationalen Norm entspricht; und

        - b) Berichterstattung zur Leistung des Informationssicherheitsmanagementsystems gegenüber der Leitung.
    - language: en
      message: |-
        Top management shall assign the responsibility and authority for:
        - a) ensuring that the information security management system conforms to the requirements of this International Standard; and

        - b) reporting on the performance of the information security management system to top management.
  attributes:
    type: Group
    changes: c
    section: H1
    structure: 5-0
  sectionType: Volume
- id: '6'
  type: Section
  title:
  - language: de
    message: 6 Planung
  - language: en
    message: 6 Planning
  children:
  - id: '1'
    type: Section
    title:
    - language: de
      message: 6.1 Maßnahmen zum Umgang mit Risiken und Chancen
    - language: en
      message: 6.1 Actions to address risks and opportunities
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: 6.1.1 Allgemeines
      - language: en
        message: 6.1.1 General
      children:
      - id: 25-compliance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 25-compliance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 25-compliance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 25-compliance-action_items
        type: Question
        questionType: audit.action_items
      - id: 25-compliance-compendium
        type: Question
        questionType: audit.compendium
      - id: 26-compliance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 26-compliance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 26-compliance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 26-compliance-action_items
        type: Question
        questionType: audit.action_items
      - id: 26-compliance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        changes: n
        section: h
        structure: 6.1.1-0
      description:
      - language: de
        message: |-
          Die Organisation muss Folgendes planen:

          - d) Maßnahmen zum Umgang mit Risiken und Chancen; und

          - e) Möglichkeiten zur
            - 1) Integration und Einbeziehung dieser Maßnahmen in die eigenen Informationssicherheitsmanagementprozesse; und
            - 2) Auswertung der Wirksamkeit dieser Maßnahmen.
      - language: en
        message: |-
          The organization shall plan:

          - d) actions to address these risks and opportunities; and

          - e) how to integrate and implement these actions into its information security management system processes; and evaluate the effectiveness of these actions.
    - id: '2'
      type: Group
      title:
      - language: de
        message: 6.1.2 Informationssicherheitsrisikobeurteilung
      - language: en
        message: 6.1.2 Information security risk assessment
      children:
      - id: 28-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 28-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 28-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 28-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 28-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 29-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 29-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 29-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 29-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 29-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        changes: n
        section: h
        structure: 6.1.2-0
      description:
      - language: de
        message: Die Organisation muss dokumentierte Informationen über den Informationssicherheitsrisikobeurteilungsprozess aufbewahren.
      - language: en
        message: The organization shall retain documented information about the information security risk assessment process.
    - id: '3'
      type: Group
      title:
      - language: de
        message: 6.1.3 Informationssicherheitsrisikobehandlung
      - language: en
        message: 6.1.3 Information security risk treatment
      children:
      - id: 31-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 31-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 31-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 31-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 31-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 32-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 32-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 32-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 32-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 32-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: 6.1.3-0
      description:
      - language: de
        message: Die Organisation muss dokumentierte Informationen über den Prozess zur Informationssicherheitsrisikobehandlung aufbewahren.
      - language: en
        message: The organization shall retain documented information about the information security risk treatment process.
    attributes:
      type: Group
      changes: n
      section: h
      structure: 6.1-0
  - id: '2'
    type: Group
    title:
    - language: de
      message: 6.2 Informationssicherheitsziele und Pläne für deren Erreichung
    - language: en
      message: 6.2 Information security objectives and planning to achieve them
    children:
    - id: 34-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 34-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 34-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 34-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 34-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 35-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 35-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 35-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 35-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 35-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 36-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 36-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 36-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 36-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 36-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 37-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 37-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 37-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 37-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 37-compliance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 6.2-0
    description:
    - language: de
      message: |-
        Bei der Planung, wie die Informationssicherheitsziele erreicht werden sollen, muss die Organisation Folgendes ermitteln:

        - f) zu ergreifende Maßnahmen;

        - g) erforderliche Ressourcen;

        - h) zuständige Personen;

        - i) Frist bis zum Abschluss; und

        - j) Verfahren zur Auswertung der Ergebnisse.
    - language: en
      message: |-
        When planning how to achieve its information security objectives, the organization shall determine:

        - f) what will be done;

        - g) what resources will be required;

        - h) who will be responsible;

        - i) when it will be completed; and

        - j) how the results will be evaluated.
  attributes:
    type: Group
    changes: n
    section: H1
    structure: 6-0
  sectionType: Volume
- id: '7'
  type: Section
  title:
  - language: de
    message: 7 Unterstützung
  - language: en
    message: 7 Support
  children:
  - id: '1'
    type: Group
    title:
    - language: de
      message: 7.1 Ressourcen
    - language: en
      message: 7.1 Resources
    children:
    - id: 40-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 40-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 40-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 40-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 40-compliance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      section: h
      structure: 7.1-0
    description:
    - language: de
      message: Die Organisation muss die zur Einrichtung, Implementierung, Aufrechterhaltung und laufenden Verbesserung des Informationssicherheitsmanagementsystems erforderlichen Ressourcen ermitteln und bereitstellen.
    - language: en
      message: The organization shall determine and provide the resources needed for the establishment, implementation, maintenance and continual improvement of the information security management system.
  - id: '2'
    type: Group
    title:
    - language: de
      message: 7.2 Kompetenz
    - language: en
      message: 7.2 Competence
    children:
    - id: 42-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 42-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 42-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 42-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 42-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      section: h
      structure: 7.2-0
    description:
    - language: de
      message: |-
        Die Organisation muss Folgendes leisten:
        - a) Ermittlung der erforderlichen Kompetenzen sowie deren Überwachung, die sich auf die Leistung im Bereich der Informationssicherheit auswirken können;

        - b) Sicherstellung, dass diese Personen durch angemessene Ausbildung, Schulung oder Erfahrung über die erforderlichen Kompetenzen verfügen;

        - c) ggf. Einleitung von Maßnahmen zur Aneignung der erforderlichen Kompetenzen und Auswertung der Wirksamkeit dieser Maßnahmen; und

        - d) Aufbewahrung entsprechender dokumentierter Informationen als Kompetenznachweis.
    - language: en
      message: |-
        The organization shall:
        - a) determine the necessary competence of person(s) doing work under its control that affects its information security performance;

        - b) ensure that these persons are competent on the basis of appropriate education, training, or experience;

        - c) where applicable, take actions to acquire the necessary competence, and evaluate the effectiveness of the actions taken; and

        - d) retain appropriate documented information as evidence of competence.
  - id: '3'
    type: Group
    title:
    - language: de
      message: 7.3 Bewusstsein
    - language: en
      message: 7.3 Awareness
    children:
    - id: 44-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 44-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 44-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 44-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 44-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 7.3-0
    description:
    - language: de
      message: |-
        Bei den Personen, die Arbeiten im Rahmen des der Tätigkeiten dieses Managementsystems verrichten, muss Bewusstsein hinsichtlich der folgenden Punkte herrschen:
        - a) Informationssicherheitspolitik

        - b) der eigene Beitrag zur Wirksamkeit des Informationssicherheitsmanagementsystems einschließlich der Vorteile einer besseren Leistung im Bereich der Informationssicherheit; und

        - c) der Auswirkungen eines Verstoßes gegen die Anforderungen des Informationssicherheitsmanagementsystems.
    - language: en
      message: |-
        Persons doing work under the organization’s control shall be aware of:
        - a) the information security policy;

        - b) their contribution to the effectiveness of the information security management system, including the benefits of improved information security performance; and

        - c) the implications of not conforming with the information security management system requirements.
  - id: '4'
    type: Group
    title:
    - language: de
      message: 7.4 Kommunikation
    - language: en
      message: 7.4 Communication
    children:
    - id: 46-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 46-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 46-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 46-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 46-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 47-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 47-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 47-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 47-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 47-compliance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 7.4-0
    description:
    - language: de
      message: |-
        Hierbei muss Folgendes berücksichtigt werden:

        - d) Kommunikationsverantwortliche; und

        - e) Kommunikationsprozesse und deren Beeinflussung.
    - language: en
      message: |-
        d) who shall communicate; and

        - e) the processes by which communication shall be effected.
  - id: '5'
    type: Section
    title:
    - language: de
      message: 7.5 Dokumentierte Informationen
    - language: en
      message: 7.5 Documented information
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: 7.5.1 Allgemeines
      - language: en
        message: 7.5.1 General
      children:
      - id: 50-compliance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 50-compliance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 50-compliance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 50-compliance-action_items
        type: Question
        questionType: audit.action_items
      - id: 50-compliance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: 7.5.1-0
      description:
      - language: de
        message: |-
          Das Informationssicherheitsmanagementsystem der Organisation muss Folgendes umfassen:
          - a) gemäß dieser Internationalen Norm erforderliche dokumentierte Informationen; und

          - b) von der Organisation als für die Wirksamkeit des Informationssicherheitsmanagementsystems erforderlich befundene dokumentierte Informationen.
      - language: en
        message: |-
          The organization’s information security management system shall include:
          - a) documented information required by this International Standard; and

          - b) documented information determined by the organization as being necessary for the effectiveness of the information security management system.
    - id: '2'
      type: Group
      title:
      - language: de
        message: 7.5.2 Erstellung und Aktualisierung
      - language: en
        message: 7.5.2 Creating and updating
      children:
      - id: 52-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 52-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 52-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 52-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 52-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: 7.5.2-0
      description:
      - language: de
        message: |-
          Bei der Erstellung und Aktualisierung der dokumentierten Informationen muss die Organisation Folgendes sicherstellen:
          - a) angemessene Kennzeichnung und Beschreibung (z. B. Titel, Datum, Autor oder Referenznummer);

          - b) angemessenes Format (z. B. Sprache, Software-Version, Grafik) und Medium (z. B. Papier, elektronisches Medium); und

          - c) Freigabe und Bewertung der Angemessenheit und Tauglichkeit.
      - language: en
        message: |-
          When creating and updating documented information the organization shall ensure appropriate:
          - a) identification and description (e.g. a title, date, author, or reference number);

          - b) format (e.g. language, software version, graphics) and media (e.g. paper, electronic); and

          - c) review and approval for suitability and adequacy.
    - id: '3'
      type: Group
      title:
      - language: de
        message: 7.5.3 Überwachung der dokumentierten Informationen
      - language: en
        message: 7.5.3 Control of documented information
      children:
      - id: 54-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 54-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 54-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 54-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 54-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 55-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 55-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 55-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 55-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 55-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 56-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 56-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 56-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 56-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 56-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: 7.5.3-0
      description:
      - language: de
        message: >-
          Dokumentierte Informationen aus externer Quelle, die von der Organisation als für die Planung und den Betrieb des Informationssicherheitsmanagementsystems für erforderlich befunden wurde, müssen entsprechend gekennzeichnet und überwacht werden.
      - language: en
        message: Documented information of external origin, determined by the organization to be necessary for the planning and operation of the information security management system, shall be identified as appropriate, and controlled.
    attributes:
      type: Group
      section: h
      structure: 7.5-0
  attributes:
    type: Group
    changes: n
    section: H1
    structure: 7-0
  sectionType: Volume
- id: '8'
  type: Section
  title:
  - language: de
    message: 8 Betrieb
  - language: en
    message: 8 Operation
  children:
  - id: '1'
    type: Group
    title:
    - language: de
      message: 8.1 Planung des Betriebes und -Überwachung
    - language: en
      message: 8.1 Operational planning and control
    children:
    - id: 59-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 59-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 59-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 59-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 59-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 60-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 60-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 60-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 60-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 60-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 61-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 61-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 61-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 61-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 61-surveilance-compendium
      type: Question
      questionType: audit.compendium
    - id: 62-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 62-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 62-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 62-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 62-surveilance-compendium
      type: Question
      questionType: audit.compendium
    - id: 63-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 63-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 63-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 63-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 63-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 8.1-0
    description:
    - language: de
      message: Die Organisation muss sicherstellen, dass ausgelagerte Prozesse festgelegt und überwacht werden.
    - language: en
      message: The organization shall ensure that outsourced processes are determined and controlled.
  - id: '2'
    type: Group
    title:
    - language: de
      message: 8.2 Informationssicherheitsrisikobewertung
    - language: en
      message: 8.2 Information security risk assessment
    children:
    - id: 65-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 65-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 65-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 65-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 65-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 66-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 66-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 66-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 66-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 66-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      section: h
      structure: 8.2-0
    description:
    - language: de
      message: Die Organisation muss die dokumentierten Informationen zu den Ergebnissen von Informationssicherheitsrisikobewertungen aufbewahren.
    - language: en
      message: The organization shall retain documented information of the results of the information security risk assessments.
  - id: '3'
    type: Group
    title:
    - language: de
      message: 8.3 Informationssicherheitsrisikobehandlung
    - language: en
      message: 8.3 Information security risk treatment
    children:
    - id: 68-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 68-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 68-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 68-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 68-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 69-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 69-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 69-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 69-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 69-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      section: h
      structure: 8.3-0
    description:
    - language: de
      message: Die Organisation muss die dokumentierten Informationen zu den Ergebnissen der Informationssicherheitsrisikobehandlung aufbewahren.
    - language: en
      message: The organization shall retain documented information of the results of the information security risk treatment.
  attributes:
    type: Group
    changes: n
    section: H1
    structure: 8-0
  sectionType: Volume
- id: '9'
  type: Section
  title:
  - language: de
    message: 9 Leistungsauswertung
  - language: en
    message: 9 Performance evaluation
  children:
  - id: '1'
    type: Group
    title:
    - language: de
      message: 9.1 Überwachung, Messung, Analyse und Auswertung
    - language: en
      message: 9.1 Monitoring, measurement, analysis and evaluation
    children:
    - id: 72-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 72-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 72-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 72-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 72-surveilance-compendium
      type: Question
      questionType: audit.compendium
    - id: 73-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 73-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 73-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 73-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 73-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 74-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 74-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 74-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 74-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 74-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 9.1-0
    description:
    - language: de
      message: Die Organisation muss angemessene dokumentierte Informationen zum Nachweis der Überwachungs- und Messergebnisse aufbewahren.
    - language: en
      message: The organization shall retain appropriate documented information as evidence of the monitoring and measurement results.
  - id: '2'
    type: Group
    title:
    - language: de
      message: 9.2 Internes Audit
    - language: en
      message: 9.2 Internal audit
    children:
    - id: 76-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 76-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 76-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 76-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 76-surveilance-compendium
      type: Question
      questionType: audit.compendium
    - id: 77-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 77-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 77-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 77-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 77-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 78-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 78-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 78-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 78-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 78-compliance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      section: h
      structure: 9.2-0
    description:
    - language: de
      message: |-
        Im Auditprogramm muss die Bedeutung der betroffenen Prozesse und der Ergebnisse vorangegangener Audits berücksichtigt werden;

        - d) Festlegung der Auditkriterien und der Geltungsbereich jedes Audits;

        - e) Auswahl der Auditoren und Durchführung von Audits zur Sicherstellung der Objektivität und Unparteilichkeit im Auditprozess;

        - f) Sicherstellung, dass Audit-Ergebnisse der relevanten Leitung gemeldet werden; und

        - g) Aufbewahrung dokumentierter Informationen als Nachweis des Auditprogramms und der Auditergebnisse.
    - language: en
      message: |-
        d) define the audit criteria and scope for each audit;

        - e) select auditors and conduct audits that ensure objectivity and the impartiality of the audit process;

        - f) ensure that the results of the audits are reported to relevant management; and

        - g) retain documented information as evidence of the audit programme(s) and the audit results.
  - id: '3'
    type: Group
    title:
    - language: de
      message: 9.3 Bewertung durch das Management
    - language: en
      message: 9.3 Management review
    children:
    - id: 80-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 80-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 80-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 80-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 80-surveilance-compendium
      type: Question
      questionType: audit.compendium
    - id: 81-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 81-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 81-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 81-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 81-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 82-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 82-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 82-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 82-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 82-surveilance-compendium
      type: Question
      questionType: audit.compendium
    - id: 83-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 83-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 83-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 83-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 83-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 9.3-0
    description:
    - language: de
      message: Die Organisation muss dokumentierte Informationen zum Nachweis der Managementbewertung aufbewahren.
    - language: en
      message: The organization shall retain documented information as evidence of the results of management reviews.
  attributes:
    type: Group
    changes: n
    section: H1
    structure: 9-0
  sectionType: Volume
- id: '10'
  type: Section
  title:
  - language: de
    message: 10 Verbesserung
  - language: en
    message: 10 Improvement
  children:
  - id: '1'
    type: Group
    title:
    - language: de
      message: 10.1 Fehler und Korrekturmaßnahmen
    - language: en
      message: 10.1 Nonconformity and corrective action
    children:
    - id: 86-compliance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 86-compliance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 86-compliance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 86-compliance-action_items
      type: Question
      questionType: audit.action_items
    - id: 86-compliance-compendium
      type: Question
      questionType: audit.compendium
    - id: 87-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 87-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 87-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 87-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 87-surveilance-compendium
      type: Question
      questionType: audit.compendium
    - id: 88-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 88-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 88-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 88-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 88-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      changes: n
      section: h
      structure: 10.1-0
    description:
    - language: de
      message: |-
        Die Organisation muss dokumentierte Informationen zum Nachweis der folgenden Aspekte aufbewahren:

        - f) Art der Fehler und jegliche ergriffene Folgemaßnahme; und

        - g) Ergebnisse eventueller Korrekturmaßnahmen.
    - language: en
      message: |-
        The organization shall retain documented information as evidence of:

        - f) the nature of the nonconformities and any subsequent actions taken, and

        - g) the results of any corrective action.
  - id: '2'
    type: Group
    title:
    - language: de
      message: 10.2 Laufende Verbesserung
    - language: en
      message: 10.2 Continual improvement
    children:
    - id: 90-surveilance
      type: Question
      questionType: audit.degree_of_fulfulment
    - id: 90-surveilance-reference_documents
      type: Question
      questionType: audit.reference_documents
    - id: 90-surveilance-audit_evidence
      type: Question
      questionType: audit.audit_evidence
    - id: 90-surveilance-action_items
      type: Question
      questionType: audit.action_items
    - id: 90-surveilance-compendium
      type: Question
      questionType: audit.compendium
    attributes:
      type: Group
      section: h
      structure: 10.2-0
    description:
    - language: de
      message: Die Organisation muss die Eignung, Angemessenheit und Wirksamkeit des Informationssicherheitsmanagementsystems laufend verbessern.
    - language: en
      message: The organization shall continually improve the suitability, adequacy and effectiveness of the information security management system.
  attributes:
    type: Group
    changes: n
    section: H1
    structure: 10-0
  sectionType: Volume
- id: ''
  type: Section
  title:
  - language: de
    message: Anhang A:Maßnahmenziele und Maßnahmen
  - language: en
    message: |-
      Annex A

      Reference control objectives and controls
  children: []
  attributes:
    type: Group
    changes: c
    section: H1
    structure: '-0'
  sectionType: Volume
- id: A
  type: Section
  sectionType: Volume
  title:
  - language: de
    message: A
  children:
  - id: '5'
    type: Section
    title:
    - language: de
      message: A.5 Sicherheitspolitiken
    - language: en
      message: A.5 Information security policies
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.5.1 Vorgaben der Leitung zur Informationssicherheit
      - language: en
        message: A.5.1 Management direction for information security
      children:
      - id: 95-compliance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 95-compliance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 95-compliance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 95-compliance-action_items
        type: Question
        questionType: audit.action_items
      - id: 95-compliance-compendium
        type: Question
        questionType: audit.compendium
      - id: 97-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 97-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 97-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 97-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 97-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.5.1-0
      description:
      - language: de
        message: 'Maßnahme: Die Informationssicherheitspolitiken müssen in planmäßigen Abständen oder jeweils nach erheblichen Änderungen bewertet werden, um sicherzustellen, dass sie nach wie vor geeignet, angemessen und wirksam sind.'
      - language: en
        message: 'Control: The policies for information security shall be reviewed at planned intervals or if significant changes occur to ensure their continuing suitability, adequacy and effectiveness.'
    attributes:
      type: Group
      section: H1
      structure: A.5-0
  - id: '6'
    type: Section
    title:
    - language: de
      message: A.6 Organisation der Informationssicherheit
    - language: en
      message: A.6 Organization of information security
    children:
    - id: '1'
      type: Group
      sectionType: Chapter
      title:
      - language: de
        message: '1'
      children:
      - id: '1'
        type: Section
        title:
        - language: de
          message: A.6.1 Interne Organisation
        - language: en
          message: A.6.1 Internal organization
        children: []
        attributes:
          type: Group
          section: h
          structure: A.6.1.1-0
      - id: '1'
        type: Section
        title:
        - language: de
          message: A.6.1.1 Rollen und Verantwortlichkeiten der Informationssicherheit
        - language: en
          message: A.6.1.1 Information security roles and responsibilities
        children: []
        attributes:
          type: Group
          section: b
          structure: A.6.1.1-0
      - id: 101-compliance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 101-compliance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 101-compliance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 101-compliance-action_items
        type: Question
        questionType: audit.action_items
      - id: 101-compliance-compendium
        type: Question
        questionType: audit.compendium
      - id: '2'
        type: Section
        title:
        - language: de
          message: A.6.1.2 Trennung von Aufgaben
        - language: en
          message: A.6.1.2 Segregation of duties
        children: []
        attributes:
          type: Group
          section: b
          structure: A.6.1.2-0
      - id: 103-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 103-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 103-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 103-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 103-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: '3'
        type: Section
        title:
        - language: de
          message: A.6.1.3 Kontakt zu Behörden
        - language: en
          message: A.6.1.3 Contact with authorities
        children: []
        attributes:
          type: Group
          section: b
          structure: A.6.1.3-0
      - id: 105-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 105-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 105-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 105-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 105-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: '4'
        type: Section
        title:
        - language: de
          message: A.6.1.4 Kontakt mit Interessenvertretungen
        - language: en
          message: A.6.1.4 Contact with special interest groups
        children: []
        attributes:
          type: Group
          section: b
          structure: A.6.1.4-0
      - id: 107-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 107-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 107-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 107-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 107-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: '5'
        type: Section
        title:
        - language: de
          message: A.6.1.5 Informationssicherheit im Projektmanagement
        - language: en
          message: A.6.1.5 Information security
        children: []
        attributes:
          type: Group
          changes: n
          section: b
          structure: A.6.1.5-0
      - id: 109-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 109-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 109-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 109-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 109-surveilance-compendium
        type: Question
        questionType: audit.compendium
      description:
      - language: de
        message: 'Maßnahme: Die Informationssicherheit muss ungeachtet der Art des Projekts im Projektmanagement berücksichtigt werden.'
      - language: en
        message: 'Control: Information security shall be addressed in project management, regardless of the type of the project.'
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.6.2 Mobilgeräte und Telearbeit
      - language: en
        message: A.6.2 Mobile devices and teleworking
      children:
      - id: '1'
        type: Section
        title:
        - language: de
          message: A.6.2.1 Politik zur Nutzung von mobilen Geräten
        - language: en
          message: A.6.2.1 Mobile device policy
        children: []
        attributes:
          type: Group
          section: b
          structure: A.6.2.1-0
      - id: 112-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 112-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 112-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 112-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 112-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: '2'
        type: Section
        title:
        - language: de
          message: A.6.2.2 Telearbeit
        - language: en
          message: A.6.2.2 Teleworking
        children: []
        attributes:
          type: Group
          section: b
          structure: A.6.2.2-0
      - id: 114-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 114-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 114-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 114-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 114-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.6.2-0
      description:
      - language: de
        message: 'Maßnahme: Es müssen eine Politik und unterstützende Sicherheitsmaßnahmen zum Schutz von Informationen festgelegt werden, auf die von Telearbeitsplätzen aus zugegriffen wird oder die dort verarbeitet oder gespeichert werden.'
      - language: en
        message: 'Control: A policy and supporting security measures shall be implemented to protect information accessed, processed or stored at teleworking sites.'
    attributes:
      type: Group
      changes: c
      section: H1
      structure: A.6-0
  - id: '7'
    type: Section
    title:
    - language: de
      message: A.7 Sicherheit des Personals
    - language: en
      message: A.7 Human resource security
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.7.1 Vor der Einstellung
      - language: en
        message: A.7.1 Prior to employment
      children:
      - id: '1'
        type: Section
        title:
        - language: de
          message: A.7.1.1 Überprüfung
        - language: en
          message: A.7.1.1 Screening
        children: []
        attributes:
          type: Group
          section: b
          structure: A.7.1.1-0
      - id: 118-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 118-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 118-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 118-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 118-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: '2'
        type: Section
        title:
        - language: de
          message: A.7.1.2 Arbeitsvertragsklauseln und -bedingungen
        - language: en
          message: A.7.1.2 Terms and conditions of employment
        children: []
        attributes:
          type: Group
          section: b
          structure: A.7.1.2-0
      - id: 120-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 120-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 120-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 120-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 120-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.7.1-0
      description:
      - language: de
        message: 'Maßnahme: Verträge von Mitarbeitern und Vertragspartnern müssen deren Verantwortung und die der Organisation für die Informationssicherheit berücksichtigen.'
      - language: en
        message: 'Control: The contractual agreements with employees and contractors shall state their and the organization’s responsibilities for information security.'
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.7.2 Während der Anstellung
      - language: en
        message: A.7.2 During employment
      children:
      - id: 123-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 123-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 123-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 123-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 123-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 125-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 125-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 125-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 125-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 125-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 127-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 127-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 127-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 127-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 127-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.7.2-0
      description:
      - language: de
        message: 'Maßnahme: Es muss ein formales und offiziell bekanntgegebenes Disziplinarverfahren festgelegt werden, umMaßnahmen gegen Mitarbeiter verhängen zu können, die gegen Informationssicherheitsvorschriften verstoßen haben.'
      - language: en
        message: 'Control: There shall be a formal and communicated disciplinary process in place to take action against employees who have committed an information security breach.'
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.7.2.1 Verantwortung des Managements
      - language: en
        message: A.7.2.1 Management responsibilities
      children: []
      attributes:
        type: Group
        section: b
        structure: A.7.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.7.2.2 Bewusstsein für Informationssicherheit, Ausbildung und Training
      - language: en
        message: A.7.2.2Information security awareness, education and training
      children: []
      attributes:
        type: Group
        section: b
        structure: A.7.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.7.2.3 Disziplinarverfahren
      - language: en
        message: A.7.2.3 Disciplinary process
      children: []
      attributes:
        type: Group
        section: b
        structure: A.7.2-0
    - id: '3'
      type: Group
      title:
      - language: de
        message: A.7.3 Beendigung und Änderung der Anstellung
      - language: en
        message: A.7.3 Termination and change of employment
      children:
      - id: 130-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 130-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 130-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 130-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 130-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.7.3-0
      description:
      - language: de
        message: >-
          Maßnahme: Verantwortlichkeiten und Pflichten im Bereich der Informationssicherheit, die auch nach Beendigung oder Änderung der Anstellung gültig bleiben, müssen definiert, dem Mitarbeiter oder Vertragspartner mitgeteilt und durchgesetzt werden.
      - language: en
        message: 'Control: Information security responsibilities and duties that remain valid after termination or change of employment shall be defined, communicated to the employee or contractor and enforced.'
    - id: '3'
      type: Section
      title:
      - language: de
        message: A.7.3.1 Verantwortung bei Beendigung oder Änderung der Anstellung
      - language: en
        message: A.7.3.1Termination or change of employment responsibilities
      children: []
      attributes:
        type: Group
        section: b
        structure: A.7.3-0
    attributes:
      type: Group
      section: H1
      structure: A.7-0
  - id: '8'
    type: Section
    title:
    - language: de
      message: A.8 Wertemanagement
    - language: en
      message: A.8 Asset management
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.8.1 Verantwortung für Werte
      - language: en
        message: A.8.1 Responsibility for assets
      children:
      - id: 134-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 134-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 134-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 134-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 134-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 136-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 136-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 136-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 136-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 136-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 138-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 138-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 138-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 138-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 138-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 140-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 140-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 140-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 140-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 140-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.8.1-0
      description:
      - language: de
        message: 'Maßnahme: Alle Mitarbeiter und Dritte müssen sämtliche Werte der Organisation, die sich bei Beendigung ihrer Anstellung oder ihres Vertrags noch in Ihrem Besitz befinden, zurückgeben'
      - language: en
        message: 'Control: All employees and external party users shall return all of the organizational assets in their possession upon termination of their employment, contract or agreement.'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.8.1.1 Inventar der Werte
      - language: en
        message: A.8.1.1 Inventory of assets
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.8.1.2 Eigentum von Werten
      - language: en
        message: A.8.1.2 Ownership of assets
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.8.1.3 Zulässiger Gebrauch von Werten
      - language: en
        message: A.8.1.3 Acceptable use of assets
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.8.1.4 Rückgabe von Werten
      - language: en
        message: A.8.1.4 Return of assets
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.1-0
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.8.2 Klassifizierung von Informationen
      - language: en
        message: A.8.2 Information classification
      children:
      - id: 143-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 143-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 143-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 143-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 143-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 145-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 145-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 145-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 145-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 145-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 147-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 147-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 147-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 147-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 147-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.8.2-0
      description:
      - language: de
        message: 'Maßnahme: Verfahren für den Umgang mit Werten sind entsprechend dem von der Organisation eingesetzten Klassifizierungs-Schema zur Einstufung von Informationen zu entwickeln und zu implementieren.'
      - language: en
        message: 'Control: Procedures for handling assets shall be developed and implemented in accordance with the information classification scheme adopted by the organization.'
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.8.2.1 Klassifizierung von Informationen
      - language: en
        message: A.8.2.1 Classification of information
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.8.2.2 Kennzeichnung von Informationen
      - language: en
        message: A.8.2.2 Labelling of information
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.8.2.3 Umgang mit Werten
      - language: en
        message: A.8.2.3 Handling of assets
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.2-0
    - id: '3'
      type: Group
      title:
      - language: de
        message: A.8.3 Umgang mit Medien
      - language: en
        message: A.8.3 Media handling
      children:
      - id: 150-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 150-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 150-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 150-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 150-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 152-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 152-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 152-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 152-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 152-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 154-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 154-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 154-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 154-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 154-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.8.3-0
      description:
      - language: de
        message: 'Maßnahme: Medien, auf denen Informationen gespeichert sind, müssen vor unautorisiertem Zugriff, missbräuchlicher Verwendung oder Verfälschung während des Transports geschützt werden.'
      - language: en
        message: 'Control: Media containing information shall be protected against unauthorized access, misuse or corruption during transportation.'
    - id: '3'
      type: Section
      title:
      - language: de
        message: A.8.3.1 Verwaltung von Wechselmedien
      - language: en
        message: A.8.3.1 Management of removable media
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.3-0
    - id: '3'
      type: Section
      title:
      - language: de
        message: A.8.3.2 Entsorgung von Medien
      - language: en
        message: A.8.3.2 Disposal of media
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.3-0
    - id: '3'
      type: Section
      title:
      - language: de
        message: A.8.3.3 Physische Weitergabe von Medien
      - language: en
        message: A.8.3.3 Physical media transfer
      children: []
      attributes:
        type: Group
        section: b
        structure: A.8.3-0
    attributes:
      type: Group
      section: H1
      structure: A.8-0
  - id: '9'
    type: Section
    title:
    - language: de
      message: A.9 Zugriffs-Überwachung
    - language: en
      message: A.9 Access control
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.9.1 Geschäftliche Anforderungen in Bezug auf die Zugriffs-Überwachung
      - language: en
        message: A.9.1 Business requirements of access control
      children:
      - id: 158-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 158-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 158-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 158-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 158-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 160-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 160-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 160-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 160-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 160-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.9.1-0
      description:
      - language: de
        message: 'Maßnahme: Benutzer dürfen ausschließlich auf diejenigen Netzwerke und Netzwerkdiensten Zugriff erhalten, zu deren Nutzung sie ausdrücklich autorisiert wurden.'
      - language: en
        message: 'Control: Users shall only be provided with access to the network and network services that they have been specifically authorized to use.'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.9.1.1 Politik zur Zugriffsüberwachung
      - language: en
        message: A.9.1.1 Access control policy
      children: []
      attributes:
        type: Group
        section: b
        structure: A.9.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.9.1.2 Zugriff auf Netzwerke und Netzwerkdienste
      - language: en
        message: A.9.1.2 Access to networks and network services
      children: []
      attributes:
        type: Group
        section: b
        structure: A.9.1-0
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.9.2 Benutzerverwaltung von autorisierten Zugriffen auf Systeme und Dienste.
      - language: en
        message: A.9.2 User access management
      children:
      - id: 163-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 163-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 163-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 163-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 163-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 165-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 165-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 165-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 165-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 165-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 167-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 167-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 167-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 167-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 167-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 169-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 169-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 169-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 169-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 169-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 171-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 171-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 171-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 171-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 171-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 173-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 173-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 173-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 173-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 173-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.9.2-0
      description:
      - language: de
        message: >-
          Maßnahme: Die Zugriffsberechtigungen aller Mitarbeiter und externen Benutzer auf Informationen und informationsverarbeitenden Einrichtungen müssen nach Beendigung der Anstellung oder des Vertrags entzogen bzw. bei einer Änderung der Anstellung entsprechend angepasst werden.
      - language: en
        message: 'Control: The access rights of all employees and external party users to information and information processing facilities shall be removed upon termination of their employment, contract or agreement, or adjusted upon change.'
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.9.2.1 An- und Abmeldung von Benutzern
      - language: en
        message: A.9.2.1 User registration and de-registration
      children: []
      attributes:
        type: Group
        section: b
        structure: A.9.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.9.2.2 Benutzerverwaltung
      - language: en
        message: A.9.2.2 User access provisioning
      children: []
      attributes:
        type: Group
        section: b
        structure: A.9.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.9.2.3 Verwaltung von Sonderrechten
      - language: en
        message: A.9.2.3 Management of privileged access rights
      children: []
      attributes:
        type: Group
        section: b
        structure: A.9.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.9.2.4 Verwaltung geheimer Authentisierungs-Informationen von Benutzern
      - language: en
        message: A.9.2.4Management of secret authentication information of users
      children: []
      attributes:
        type: Group
        section: b
        structure: A.9.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.9.2.5 Prüfung von Zugriffsberechtigungen der Benutzer
      - language: en
        message: A.9.2.5 Review of user access rights
      children: []
      attributes:
        type: Group
        section: b
        structure: A.9.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.9.2.6 Entzug oder Anpassung von Zugriffsberechtigungen
      - language: en
        message: A.9.2.6 Removal or adjustment of access rights
      children: []
      attributes:
        type: Group
        section: b
        structure: A.9.2-0
    - id: '3'
      type: Group
      title:
      - language: de
        message: A.9.3 Benutzerverantwortung
      - language: en
        message: A.9.3 User responsibilities
      children:
      - id: 176-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 176-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 176-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 176-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 176-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.9.3-0
      description:
      - language: de
        message: 'Maßnahme: Von den Benutzern muss verlangt werden, die sicherheitsrelevanten Praktiken der Organisation zur Verwendung von geheimen Authentisierungsinformationen zu befolgen.'
      - language: en
        message: 'Control: Users shall be required to follow the organization’s practices in the use of secret authentication information.'
    - id: '3'
      type: Section
      title:
      - language: de
        message: A.9.3.1 Verwendung von geheimen Authentisierungs-Informationen
      - language: en
        message: A.9.3.1 Use of secret authentication information
      children: []
      attributes:
        type: Group
        section: b
        structure: A.9.3-0
    - id: '4'
      type: Section
      title:
      - language: de
        message: A.9.4 Überwachung des Zugriffs auf Systeme und Anwendungen
      - language: en
        message: A.9.4 System and application access control
      children:
      - id: '1'
        type: Group
        title:
        - language: de
          message: A.9.4.1 Beschränkung des Zugriffs auf Informationen
        - language: en
          message: A.9.4.1 Information access restriction
        children:
        - id: 179-surveilance
          type: Question
          questionType: audit.degree_of_fulfulment
        - id: 179-surveilance-reference_documents
          type: Question
          questionType: audit.reference_documents
        - id: 179-surveilance-audit_evidence
          type: Question
          questionType: audit.audit_evidence
        - id: 179-surveilance-action_items
          type: Question
          questionType: audit.action_items
        - id: 179-surveilance-compendium
          type: Question
          questionType: audit.compendium
        attributes:
          type: Group
          section: b
          structure: A.9.4.1-0
        description:
        - language: de
          message: 'Maßnahme: Der Zugriff auf Funktionen von Informations- und Anwendungssystemen muss entsprechend der Politik zur Zugriffsüberwachung beschränkt werden.'
        - language: en
          message: 'Control: Access to information and application system functions shall be restricted in accordance with the access control policy.'
      - id: '2'
        type: Group
        title:
        - language: de
          message: A.9.4.2 Sichere Anmeldeverfahren
        - language: en
          message: A.9.4.2 Secure log-on procedures
        children:
        - id: 181-surveilance
          type: Question
          questionType: audit.degree_of_fulfulment
        - id: 181-surveilance-reference_documents
          type: Question
          questionType: audit.reference_documents
        - id: 181-surveilance-audit_evidence
          type: Question
          questionType: audit.audit_evidence
        - id: 181-surveilance-action_items
          type: Question
          questionType: audit.action_items
        - id: 181-surveilance-compendium
          type: Question
          questionType: audit.compendium
        attributes:
          type: Group
          section: b
          structure: A.9.4.2-0
        description:
        - language: de
          message: 'Maßnahme: Der Zugriff auf Systeme und Anwendungen muss über ein sicheres Anmeldeverfahren kontrolliert werden, wenn dies gemäß der Zugriffsüberwachungspolitik erforderlich ist.'
        - language: en
          message: 'Control: Where required by the access control policy, access to systems and applications shall be controlled by a secure log-on procedure.'
      - id: '3'
        type: Group
        title:
        - language: de
          message: A.9.4.3 Passwort Managementsystem
        - language: en
          message: A.9.4.3 Password management system
        children:
        - id: 183-surveilance
          type: Question
          questionType: audit.degree_of_fulfulment
        - id: 183-surveilance-reference_documents
          type: Question
          questionType: audit.reference_documents
        - id: 183-surveilance-audit_evidence
          type: Question
          questionType: audit.audit_evidence
        - id: 183-surveilance-action_items
          type: Question
          questionType: audit.action_items
        - id: 183-surveilance-compendium
          type: Question
          questionType: audit.compendium
        attributes:
          type: Group
          section: b
          structure: A.9.4.3-0
        description:
        - language: de
          message: 'Maßnahme: Passwort Managementsysteme müssen starke Passwörter einfordern und interaktiv sein .'
        - language: en
          message: 'Control: Password management systems shall be interactive and shall ensure quality passwords.'
      - id: '4'
        type: Group
        title:
        - language: de
          message: A.9.4.4 Verwendung von Anwenderprogrammen mit Sonderberechtigungen
        - language: en
          message: A 9.4.3.4 Use of privileged utility programs
        children:
        - id: 185-surveilance
          type: Question
          questionType: audit.degree_of_fulfulment
        - id: 185-surveilance-reference_documents
          type: Question
          questionType: audit.reference_documents
        - id: 185-surveilance-audit_evidence
          type: Question
          questionType: audit.audit_evidence
        - id: 185-surveilance-action_items
          type: Question
          questionType: audit.action_items
        - id: 185-surveilance-compendium
          type: Question
          questionType: audit.compendium
        attributes:
          type: Group
          section: b
          structure: A.9.4.4-0
        description:
        - language: de
          message: 'Maßnahme: Die Verwendung von Anwenderprogrammen, die die Möglichkeit der Umgehung von System- und Anwendungs-Überwachungen bieten, müssen beschränkt und streng überwacht werden.'
        - language: en
          message: 'Control: The use of utility programs that might be capable of overriding system and application controls shall be restricted and tightly controlled.'
      - id: '5'
        type: Group
        title:
        - language: de
          message: A.9.4.5 Überwachung des Zugriffs auf Software-Quellcode
        - language: en
          message: A.9.4.5 Access control to program source code
        children:
        - id: 187-surveilance
          type: Question
          questionType: audit.degree_of_fulfulment
        - id: 187-surveilance-reference_documents
          type: Question
          questionType: audit.reference_documents
        - id: 187-surveilance-audit_evidence
          type: Question
          questionType: audit.audit_evidence
        - id: 187-surveilance-action_items
          type: Question
          questionType: audit.action_items
        - id: 187-surveilance-compendium
          type: Question
          questionType: audit.compendium
        attributes:
          type: Group
          section: b
          structure: A.9.4.5-0
        description:
        - language: de
          message: 'Maßnahme: Der Zugriff auf den Software-Quellcode muss beschränkt werden.'
        - language: en
          message: 'Control: Access to program source code shall be restricted.'
      attributes:
        type: Group
        section: h
        structure: A.9.4-0
    attributes:
      type: Group
      section: H1
      structure: A.9-0
  - id: '10'
    type: Section
    title:
    - language: de
      message: A.10 Kryptographie
    - language: en
      message: A.10 Cryptography
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.10.1 KryptographischeMaßnahmen
      - language: en
        message: A.10.1 Cryptographic controls
      children:
      - id: 191-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 191-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 191-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 191-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 191-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 193-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 193-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 193-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 193-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 193-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.10.1-0
      description:
      - language: de
        message: 'Maßnahme: Eine Politik zur Verwendung, zum Schutz und zur Gültigkeitsdauer von kryptographischen Schlüsseln ist zu entwickeln und über deren gesamten Nutzungsdauer hinweg umzusetzen.'
      - language: en
        message: 'Control: A policy on the use, protection and lifetime of cryptographic keys shall be developed and implemented through their whole lifecycle.'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.10.1.1 Politik zur Nutzung von kryptographischenMaßnahmen
      - language: en
        message: A.10.1.1 Policy on the use of cryptographic controls
      children: []
      attributes:
        type: Group
        section: b
        structure: A.10.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.10.1.2 Verwaltung von Schlüsseln
      - language: en
        message: A.10.1.2 Key management
      children: []
      attributes:
        type: Group
        section: b
        structure: A.10.1-0
    attributes:
      type: Group
      section: H1
      structure: A.10-0
  - id: '11'
    type: Section
    title:
    - language: de
      message: A.11 Physikalische und umgebungsbezogene Sicherheit
    - language: en
      message: A.11 Physical and environmental security
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.11.1 Sicherheitsbereiche
      - language: en
        message: A.11.1 Secure areas
      children:
      - id: 197-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 197-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 197-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 197-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 197-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 199-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 199-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 199-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 199-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 199-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 201-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 201-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 201-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 201-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 201-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 203-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 203-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 203-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 203-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 203-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 205-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 205-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 205-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 205-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 205-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 207-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 207-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 207-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 207-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 207-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.11.1-0
      description:
      - language: de
        message: >-
          Maßnahme: Zugangspunkte wie Anlieferungs- und Ladezonen sowie andere Punkte, über die sich unautorisierte Personen Zugang zu den Betriebsgebäuden verschaffen könnten, müssen überwacht und nach Möglichkeit von informationsverarbeitenden Einrichtungen isoliert werden, um unautorisierten Zutritt zu verhindern.
      - language: en
        message: 'Control: Access points such as delivery and loading areas and other points where unauthorized persons could enter the premises shall be controlled and, if possible, isolated from information processing'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.11.1.1 Physikalische Sicherheitszone
      - language: en
        message: A.11.1.1 Physical security perimeter
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.11.1.2 Physikalische Zutritts-Überwachung
      - language: en
        message: A.11.1.2 Physical entry controls
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.1-0
        assessment:
          de: Maßnahmenziele und Maßnahmen
          en: Control objectives and controls
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.11.1.3 Sicherung von Büros, Räumen und Einrichtungen
      - language: en
        message: A.11.1.3 Securing offices, rooms and facilities
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.11.1.4 Schutz vor externen und umweltbedingten Bedrohungen
      - language: en
        message: A.11.1.4Protecting against external and environmental threats
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.11.1.5 Arbeit in Sicherheitsbereichen
      - language: en
        message: A.11.1.5 Working in secure areas
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.11.1.6 Anlieferungs- und Ladezonen
      - language: en
        message: A.11.1.6 Delivery and loading areas
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.1-0
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.11.2 Betriebsmittel
      - language: en
        message: A.11.2 Equipment
      children:
      - id: 210-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 210-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 210-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 210-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 210-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 212-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 212-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 212-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 212-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 212-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 214-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 214-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 214-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 214-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 214-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 216-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 216-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 216-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 216-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 216-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 218-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 218-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 218-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 218-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 218-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 220-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 220-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 220-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 220-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 220-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 222-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 222-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 222-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 222-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 222-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 224-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 224-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 224-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 224-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 224-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 226-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 226-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 226-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 226-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 226-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.11.2-0
      description:
      - language: de
        message: 'Maßnahme: Eine Politik des aufgeräumten Schreibtisches für Papiere und Wechselmedien sowie des leeren Bildschirms für informationsverarbeitende Einrichtungen muss Anwendung finden.'
      - language: en
        message: 'Control: A clear desk policy for papers and removable storage media and a clear screen policy for information processing facilities shall be adopted.'
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.11.2.1 Platzierung und Schutz von Betriebsmitteln
      - language: en
        message: A.11.2.1 Equipment siting and protection
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.11.2.2 Versorgungseinrichtungen
      - language: en
        message: A.11.2.2 Supporting utilities
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.11.2.3 Sicherheit der Verkabelung
      - language: en
        message: A.11.2.3 Cabling security
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.11.2.4 Instandhaltung von Gerätschaften
      - language: en
        message: A.11.2.4 Equipment maintenance
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.11.2.5 Entfernung von Werten
      - language: en
        message: A.11.2.5 Removal of assets
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.11.2.6 Sicherheit von Betriebsmitteln und Werten außerhalb der Betriebsgebäude
      - language: en
        message: A.11.2.6Security of equipment and assets off-premises
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.11.2.7 Sichere Entsorgung oder Wiederverwendung von Betriebsmitteln
      - language: en
        message: A.11.2.7 Secure disposal or reuse of equipment
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.11.2.8 Unbeaufsichtigte Benutzerausstattung
      - language: en
        message: A.11.2.8 Unattended user equipment
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.11.2.9 Politik des aufgeräumten Schreibtischs und des leeren Bildschirms
      - language: en
        message: A.11.2.9 Clear desk and clear screen policy
      children: []
      attributes:
        type: Group
        section: b
        structure: A.11.2-0
    attributes:
      type: Group
      section: H1
      structure: A.11-0
  - id: '12'
    type: Section
    title:
    - language: de
      message: A.12 Betriebssicherheit
    - language: en
      message: A.12 Operations security
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.12.1 Betriebsverfahren und Zuständigkeiten
      - language: en
        message: A.12.1 Operational procedures and responsibilities
      children:
      - id: 230-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 230-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 230-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 230-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 230-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 232-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 232-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 232-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 232-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 232-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 234-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 234-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 234-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 234-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 234-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 236-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 236-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 236-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 236-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 236-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.12.1-0
      description:
      - language: de
        message: 'Maßnahme: Entwicklungs-, Test- und Betriebsumgebungen sind zu trennen, um das Risiko unautorisierter Zugriffe oder unautorisierter Änderungen an der Betriebsumgebung zu verringern.'
      - language: en
        message: 'Control: Development, testing, and operational environments shall be separated to reduce the risks of unauthorized access or changes to the operational environment.'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.12.1.1 Dokumentierte Betriebsverfahren
      - language: en
        message: A.12.1.1 Documented operating procedures
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.12.1.2 Änderungs-Management
      - language: en
        message: A.12.1.2 Change management
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.12.1.3 Kapazitäts-Management
      - language: en
        message: A.12.1.3 Capacity management
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.12.1.4 Trennung von Entwicklungs-, Test- und Betriebsumgebungen
      - language: en
        message: A.12.1.4Separation of development, testing and operational environments
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.1-0
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.12.2 Schutz vor Malware
      - language: en
        message: A.12.2 Protection from malware
      children:
      - id: 239-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 239-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 239-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 239-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 239-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.12.2-0
      description:
      - language: de
        message: 'Maßnahme: Es sind Erkennungs-, Präventions- und Wiederherstellungsmaßnahmen zum Schutz vor bösartiger Software in Verbindung mit einer angemessenen Sensibilisierung der Benutzer zu implementieren.'
      - language: en
        message: 'Control: Detection, prevention and recovery controls to protect against malware shall be implemented, combined with appropriate user awareness.'
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.12.2.1Maßnahmen zum Schutz vor bösartiger Software
      - language: en
        message: A.12.2.1 Controls against malware
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.2-0
    - id: '3'
      type: Group
      title:
      - language: de
        message: A.12.3 Datensicherungen
      - language: en
        message: A.12.3 Backup
      children:
      - id: 242-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 242-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 242-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 242-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 242-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.12.3-0
      description:
      - language: de
        message: 'Maßnahme: Es sind Sicherungskopien von Daten und Software sowie System-Images anzufertigen und regelmäßig entsprechend der vereinbarten Datensicherungspolitik zu prüfen.'
      - language: en
        message: 'Control: Backup copies of information, software and system images shall betaken and tested regularly in accordance with an agreed backup policy.'
    - id: '3'
      type: Section
      title:
      - language: de
        message: A.12.3.1 Datensicherungen von Informationen
      - language: en
        message: A.12.3.1 Information backup
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.3-0
    - id: '4'
      type: Group
      title:
      - language: de
        message: A.12.4 Protokollierung und Überwachung
      - language: en
        message: A.12.4 Logging and monitoring
      children:
      - id: 245-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 245-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 245-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 245-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 245-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 247-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 247-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 247-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 247-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 247-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 249-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 249-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 249-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 249-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 249-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 251-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 251-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 251-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 251-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 251-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.12.4-0
      description:
      - language: de
        message: 'Maßnahme: Die Uhren aller relevanten Datenverarbeitungssysteme innerhalb einer Organisation oder einer Sicherheitsdomäne müssen auf eine Referenz-Zeitquelle synchronisiert werden.'
      - language: en
        message: 'Control: The clocks of all relevant information processing systems within an organization or security domain shall be synchronised to a single reference time source.'
    - id: '4'
      type: Section
      title:
      - language: de
        message: A.12.4.1 Ereignisprotokollierung
      - language: en
        message: A.12.4.1 Event logging
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.4-0
    - id: '4'
      type: Section
      title:
      - language: de
        message: A.12.4.2 Schutz von Protokoll-Informationen
      - language: en
        message: A.12.4.2 Protection of log information
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.4-0
    - id: '4'
      type: Section
      title:
      - language: de
        message: A.12.4.3 Administrator- und Betreiberprotokolle
      - language: en
        message: A.12.4.3 Administrator and operator logs
      children: []
      attributes:
        type: Group
        changes: n
        section: b
        structure: A.12.4-0
    - id: '4'
      type: Section
      title:
      - language: de
        message: A.12.4.4 Zeitsynchronisation
      - language: en
        message: A.12.4.4 Clock synchronisation
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.4-0
    - id: '5'
      type: Group
      title:
      - language: de
        message: A.12.5 Überwachung von Software im Betrieb
      - language: en
        message: A.12.5 Control of operational software
      children:
      - id: 254-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 254-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 254-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 254-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 254-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.12.5-0
      description:
      - language: de
        message: 'Maßnahme: Es sind Verfahren zur Überwachung der Installation von Software auf betriebsrelevanten Systemen zu implementieren.'
      - language: en
        message: 'Control: Procedures shall be implemented to control the installation of software on operational systems.'
    - id: '5'
      type: Section
      title:
      - language: de
        message: A.12.5.1 Installation von Software auf Systemen im Betrieb
      - language: en
        message: A.12.5.1Installation of software on operational systems
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.5-0
    - id: '6'
      type: Group
      title:
      - language: de
        message: A.12.6 Management technischer Schwachstellen
      - language: en
        message: A.12.6 Technical vulnerability management
      children:
      - id: 257-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 257-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 257-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 257-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 257-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 259-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 259-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 259-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 259-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 259-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.12.6-0
      description:
      - language: de
        message: 'Maßnahme: Für Software-Installationen durch Benutzer müssen Regeln festgelegt und implementiert werden.'
      - language: en
        message: 'Control: Rules governing the installation of software by users shall be established and implemented.'
    - id: '6'
      type: Section
      title:
      - language: de
        message: A.12.6.1 Management technischer Schwachstellen
      - language: en
        message: A.12.6.1 Management of technical vulnerabilities
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.6-0
    - id: '6'
      type: Section
      title:
      - language: de
        message: A.12.6.2 Beschränkungen der Software-Installation
      - language: en
        message: A.12.6.2 Restrictions on software installation
      children: []
      attributes:
        type: Group
        changes: n
        section: b
        structure: A.12.6-0
    - id: '7'
      type: Group
      title:
      - language: de
        message: A.12.7 Auswirkungen von Audits auf Informationssysteme
      - language: en
        message: A.12.7 Information systems audit considerations
      children:
      - id: 262-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 262-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 262-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 262-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 262-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.12.7-0
      description:
      - language: de
        message: 'Maßnahme: Audit-Anforderungen und -Aktivitäten im Zusammenhang mit betriebsrelevanten Systemen müssen sorgfältig geplant und vereinbart werden, um Unterbrechungen der Geschäftsabläufe zu minimieren.'
      - language: en
        message: 'Control: Audit requirements and activities involving verification of operational systems shall be carefully planned and agreed to minimise disruptions to business processes.'
    - id: '7'
      type: Section
      title:
      - language: de
        message: A.12.7.1 Audits von Informationssystemen
      - language: en
        message: A.12.7.1 Information systems audit controls
      children: []
      attributes:
        type: Group
        section: b
        structure: A.12.7-0
    attributes:
      type: Group
      section: H1
      structure: A.12-0
  - id: '13'
    type: Section
    title:
    - language: de
      message: A.13 Sicherheit in der Kommunikation
    - language: en
      message: A.13 Communications security
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.13.1 Netzwerksicherheitsmanagement
      - language: en
        message: A.13.1 Network security management
      children:
      - id: 266-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 266-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 266-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 266-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 266-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 268-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 268-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 268-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 268-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 268-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 270-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 270-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 270-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 270-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 270-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.13.1-0
      description:
      - language: de
        message: 'Maßnahme: Gruppen von Informationsdiensten, Benutzern und Informationssystemen müssen in Netzwerken voneinander getrennt gehalten werden.'
      - language: en
        message: 'Control: Groups of information services, users and information systems shall be segregated on networks.'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.13.1.1Maßnahmen zur Überwachung des Netzwerks
      - language: en
        message: A.13.1.1 Network controls
      children: []
      attributes:
        type: Group
        section: b
        structure: A.13.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.13.1.2 Sicherheit von Netzwerkdiensten
      - language: en
        message: A.13.1.2 Security of network
      children: []
      attributes:
        type: Group
        section: b
        structure: A.13.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.13.1.3 Trennung in Netzwerken
      - language: en
        message: A.13.1.3 Segregation in networks
      children: []
      attributes:
        type: Group
        section: b
        structure: A.13.1-0
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.13.2 Informationsübertragung
      - language: en
        message: A.13.2 Information transfer
      children:
      - id: 273-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 273-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 273-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 273-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 273-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 275-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 275-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 275-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 275-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 275-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 277-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 277-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 277-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 277-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 277-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 279-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 279-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 279-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 279-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 279-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.13.2-0
      description:
      - language: de
        message: 'Maßnahme: Entsprechend den Bedürfnissen der Organisation in Bezug auf den Schutz von Informationen müssen Anforderungen für Vertraulichkeits- oder Geheimhaltungsvereinbarungen ermittelt, regelmäßig geprüft und dokumentiert werden.'
      - language: en
        message: 'Control: Requirements for confidentiality or non-disclosure agreements reflecting the organization’s needs for the protection of information shall be identified, regularly reviewed and documented.'
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.13.2.1 Politiken und Verfahren für die Informationsübertragung
      - language: en
        message: A.13.2.1Information transfer policies and procedures
      children: []
      attributes:
        type: Group
        changes: c
        section: b
        structure: A.13.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.13.2.2 Vereinbarungen zur Informationsübertragung
      - language: en
        message: A.13.2.2 Agreements on information transfer
      children: []
      attributes:
        type: Group
        section: b
        structure: A.13.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.13.2.3 Elektronische Nachrichtenübermittlung
      - language: en
        message: A.13.2.3 Electronic messaging
      children: []
      attributes:
        type: Group
        section: b
        structure: A.13.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.13.2.4 Vertraulichkeits- oder Geheimhaltungsvereinbarungen
      - language: en
        message: A.13.2.4 Confidentiality or nondisclosure agreements
      children: []
      attributes:
        type: Group
        changes: c
        section: b
        structure: A.13.2-0
    attributes:
      type: Group
      section: H1
      structure: A.13-0
  - id: '14'
    type: Section
    title:
    - language: de
      message: A.14 Beschaffung, Entwicklung und Instandhaltung von Systemen
    - language: en
      message: A.14 System acquisition, development and maintenance
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.14.1 Sicherheitsanforderungen für Informationssysteme
      - language: en
        message: A.14.1 Security requirements of information systems
      children:
      - id: 283-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 283-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 283-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 283-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 283-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 285-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 285-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 285-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 285-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 285-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 287-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 287-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 287-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 287-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 287-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.14.1-0
      description:
      - language: de
        message: >-
          Maßnahme: Informationen, die im Zuge von Transaktionen im Zusammenhang mit Anwendungsdiensten übertragen werden, müssen geschützt werden, um unvollständigen Übertragungen und Fehlleitungen sowie unautorisierten Offenlegungen, Vervielfältigungen oder wiederholten Wiedergaben von Nachrichten vorzubeugen.
      - language: en
        message: >-
          Control: Information involved in application service transactions shall be protected to prevent incomplete transmission, mis-routing, unauthorized message alteration, unauthorized disclosure, unauthorized message duplication or replay.
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.14.1.1 Analyse und Spezifikation von Sicherheitsanforderungen
      - language: en
        message: A.14.1.1Information security requirements analysis and specification
      children: []
      attributes:
        type: Group
        section: b
        structure: A.14.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.14.1.2 Sicherung von Anwendungsdiensten in öffentlichen Netzen
      - language: en
        message: A.14.1.2 Securing application services on public networks
      children: []
      attributes:
        type: Group
        changes: n
        section: b
        structure: A.14.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.14.1.3 Schutz von Transaktionen im Zusammenhang mit Anwendungsdiensten
      - language: en
        message: A.14.1.3 Protecting application services transactions
      children: []
      attributes:
        type: Group
        section: b
        structure: A.14.1-0
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.14.2 Sicherheit in Entwicklungs- und Unterstützungsprozessen
      - language: en
        message: A.14.2 Security in development and support processes
      children:
      - id: 290-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 290-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 290-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 290-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 290-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 292-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 292-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 292-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 292-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 292-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 294-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 294-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 294-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 294-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 294-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 296-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 296-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 296-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 296-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 296-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 298-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 298-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 298-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 298-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 298-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 300-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 300-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 300-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 300-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 300-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 302-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 302-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 302-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 302-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 302-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 304-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 304-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 304-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 304-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 304-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 306-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 306-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 306-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 306-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 306-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.14.2-0
      description:
      - language: de
        message: 'Maßnahme: Für neue Informationssysteme, Upgrades und neue Versionen sind Abnahmeprüfungsprogramme und dazugehörige Kriterien festzulegen.'
      - language: en
        message: 'Control: Acceptance testing programs and related criteria shall be established for new information systems, upgrades and new versions.'
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.14.2.1 Politik für sichere Entwicklung
      - language: en
        message: A.14.2.1 Secure development policy
      children: []
      attributes:
        type: Group
        changes: n
        section: b
        structure: A.14.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.14.2.2 Überwachung von Änderungen an Systemen
      - language: en
        message: A.14.2.2 System change control procedures
      children: []
      attributes:
        type: Group
        changes: c
        section: b
        structure: A.14.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.14.2.3 Technische Prüfung von Anwendungen nach Wechseln der Anwendungsplattform
      - language: en
        message: A.14.2.3Technical review of applications after operating platform changes
      children: []
      attributes:
        type: Group
        section: b
        structure: A.14.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.14.2.4 Beschränkung von Änderungen an Software-Paketen
      - language: en
        message: A.14.2.4Restrictions on changes to software packages
      children: []
      attributes:
        type: Group
        section: b
        structure: A.14.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.14.2.5 Sichere Systementwicklungsverfahren
      - language: en
        message: A.14.2.5 Secure system engineering principles
      children: []
      attributes:
        type: Group
        section: b
        structure: A.14.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.14.2.6 Sichere Entwicklungsumgebung
      - language: en
        message: A.14.2.6 Secure development environment
      children: []
      attributes:
        type: Group
        changes: n
        section: b
        structure: A.14.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.14.2.7 Ausgelagerte Entwicklung
      - language: en
        message: A.14.2.7 Outsourced development
      children: []
      attributes:
        type: Group
        changes: c
        section: b
        structure: A.14.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.14.2.8 Systemsicherheitsprüfungen
      - language: en
        message: A.14.2.8 System security testing
      children: []
      attributes:
        type: Group
        changes: n
        section: b
        structure: A.14.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.14.2.9 Systemabnahmeprüfung
      - language: en
        message: A.14.2.9 System acceptance testing
      children: []
      attributes:
        type: Group
        section: b
        structure: A.14.2-0
    - id: '3'
      type: Group
      title:
      - language: de
        message: A.14.3 Prüfdaten
      - language: en
        message: A.14.3 Test data
      children:
      - id: 308-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 308-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 308-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 308-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 308-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.14.3-0
      description:
      - language: de
        message: 'A.14.3.1 Schutz von Prüfdaten Maßnahme: Prüfdaten müssen sorgfältig ausgewählt, geschützt und überwacht werden.'
      - language: en
        message: 'Control: Test data shall be selected carefully, protected and controlled.'
    attributes:
      type: Group
      section: H1
      structure: A.14-0
  - id: '15'
    type: Section
    title:
    - language: de
      message: A.15 Lieferantenbeziehungen
    - language: en
      message: A.15 Supplier relationships
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.15.1 Sicherheit in Lieferantenbeziehungen
      - language: en
        message: A.15.1 Information security in supplier relationships
      children:
      - id: 312-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 312-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 312-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 312-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 312-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 314-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 314-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 314-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 314-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 314-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 316-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 316-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 316-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 316-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 316-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        changes: n
        section: h
        structure: A.15.1-0
      description:
      - language: de
        message: >-
          Maßnahme: Vereinbarungen mit Lieferanten müssen Anforderungen für den Umgang mit Informationssicherheitsrisiken im Zusammenhang mit der Dienstleistungs- und Produktlieferkette im Bereich der Informations- und Kommunikationstechnologie enthalten.
      - language: en
        message: 'Control: Agreements with suppliers shall include requirements to address the information security risks associated with information and communications technology services and product supply chain.'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.15.1.1 Informationssicherheitspolitik für Lieferantenbeziehungen
      - language: en
        message: A.15.1.1Information security policy for supplier relationships
      children: []
      attributes:
        type: Group
        changes: n
        section: b
        structure: A.15.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.15.1.2 Sicherheitsthemen in Lieferantenverträgen
      - language: en
        message: A.15.1.2 Addressing security within supplier agreements
      children: []
      attributes:
        type: Group
        section: b
        structure: A.15.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.15.1.3 Informationswege und -Technologien in Lieferketten
      - language: en
        message: A.15.1.3 Information and communication technology supply chain
      children: []
      attributes:
        type: Group
        changes: n
        section: b
        structure: A.15.1-0
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.15.2 Management der Dienstleistungserbringung durch Lieferanten
      - language: en
        message: A.15.2 Supplier service delivery management
      children:
      - id: 319-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 319-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 319-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 319-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 319-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 321-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 321-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 321-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 321-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 321-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        changes: n
        section: h
        structure: A.15.2-0
      description:
      - language: de
        message: >-
          Maßnahme: Änderungen an der Erbringung von Dienstleistungen durch Lieferanten einschließlich der Pflege und Verbesserung bestehender Informationssicherheitspolitiken, -verfahren und -Überwachungn müssen unter Berücksichtigung der Kritikalität der betroffenen geschäftlichen Informationen, Systeme und Prozesse müssen verwaltet sowie einer erneuten Risikobewertung unterzogen werden.
      - language: en
        message: >-
          Control: Changes to the provision of services by suppliers, including maintaining and improving existing information security policies, procedures and controls, shall be managed, taking account of the criticality of business information, systems and processes involved and re-assessment of risks.
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.15.2.1 Überwachung und Prüfung von Lieferantendienstleistungen
      - language: en
        message: A.15.2.1 Monitoring and review of supplier services
      children: []
      attributes:
        type: Group
        section: b
        structure: A.15.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.15.2.2 Management von Änderungen an Lieferantendienstleistungen
      - language: en
        message: A.15.2.2 Managing changes to supplier services
      children: []
      attributes:
        type: Group
        section: b
        structure: A.15.2-0
    attributes:
      type: Group
      changes: n
      section: H1
      structure: A.15-0
  - id: '16'
    type: Section
    title:
    - language: de
      message: A.16 Management von Informationssicherheitsvorfällen
    - language: en
      message: A.16 Information security incident management
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.16.1 Management von Informationssicherheitsvorfällen und Verbesserungen
      - language: en
        message: A.16.1 Management of information security incidents and improvements
      children:
      - id: 325-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 325-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 325-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 325-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 325-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 327-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 327-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 327-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 327-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 327-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 329-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 329-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 329-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 329-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 329-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 331-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 331-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 331-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 331-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 331-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 333-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 333-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 333-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 333-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 333-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 335-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 335-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 335-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 335-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 335-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 337-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 337-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 337-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 337-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 337-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.16.1-0
      description:
      - language: de
        message: 'Maßnahme: Die Organisation muss Verfahren für die Ermittlung, Sammlung, Aneignung und Aufbewahrung von Informationen, die als Beweismaterial dienen können, festlegen und anwenden.'
      - language: en
        message: 'Control: The organization shall define and apply procedures for the identification, collection, acquisition and preservation of information, which can serve as evidence.'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.16.1.1 Verantwortlichkeiten und Verfahren
      - language: en
        message: A.16.1.1 Responsibilities and procedures
      children: []
      attributes:
        type: Group
        section: b
        structure: A.16.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.16.1.2 Meldung von Informationssicherheitsereignissen
      - language: en
        message: A.16.1.2 Reporting information security events
      children: []
      attributes:
        type: Group
        section: b
        structure: A.16.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.16.1.3 Meldung von Informations-Sicherheits-Schwachstellen
      - language: en
        message: A.16.1.3 Reporting information security weaknesses
      children: []
      attributes:
        type: Group
        section: b
        structure: A.16.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.16.1.4 Bewertung und Einstufung von Informations-Sicherheits-Ereignissen
      - language: en
        message: A.16.1.4Assessment of and decision on information security events
      children: []
      attributes:
        type: Group
        changes: n
        section: b
        structure: A.16.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.16.1.5 Reaktion auf Informations-Sicherheitsvorfälle
      - language: en
        message: A.16.1.5 Response to information security incidents
      children: []
      attributes:
        type: Group
        changes: c
        section: b
        structure: A.16.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.16.1.6 Erkenntnisse aus Informations-Sicherheitsvorfällen
      - language: en
        message: A.16.1.6Learning from information security incidents
      children: []
      attributes:
        type: Group
        section: b
        structure: A.16.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.16.1.7 Sammeln von Nachweisen
      - language: en
        message: A.16.1.7 Collection of evidence
      children: []
      attributes:
        type: Group
        section: b
        structure: A.16.1-0
    attributes:
      type: Group
      section: H1
      structure: A.16-0
  - id: '17'
    type: Section
    title:
    - language: de
      message: A.17 Informationssicherheitsaspekte des Business Continuity Management
    - language: en
      message: A.17 Information security aspects of business continuity management
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.17.1 Kontinuität der Informationssicherheit
      - language: en
        message: A.17.1 Information security continuity
      children:
      - id: 341-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 341-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 341-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 341-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 341-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 343-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 343-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 343-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 343-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 343-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 345-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 345-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 345-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 345-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 345-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.17.1-0
      description:
      - language: de
        message: >-
          Maßnahme: Die Organisation muss die festgelegten und implementierten Kontrollmaßnahmen für die Kontinuität der Informationssicherheit in regelmäßigen Abständen überprüfen, um sicherzustellen, dass sie gültig und auch in schwierigen Situationen wirksam sind.
      - language: en
        message: 'Control: The organization shall verify the established and implemented information security continuity controls at regular intervals in order to ensure that they are valid and effective during adverse situations.'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.17.1.1 Planung der Kontinuität der Informationssicherheit
      - language: en
        message: A.17.1.1 Planning information security continuity
      children: []
      attributes:
        type: Group
        section: b
        structure: A.17.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.17.1.2 Implementierung der Kontinuität der Informationssicherheit
      - language: en
        message: A.17.1.2Implementing information security continuity
      children: []
      attributes:
        type: Group
        section: b
        structure: A.17.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.17.1.3 Überprüfung, Überarbeitung und Auswertung der Kontinuität der Informationssicherheit
      - language: en
        message: 'A.17.1.3: Verify, review and evaluate information security continuity'
      children: []
      attributes:
        type: Group
        section: b
        structure: A.17.1-0
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.17.2 Redundanzen
      - language: en
        message: A.17.2 Redundancies
      children:
      - id: 348-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 348-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 348-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 348-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 348-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.17.2-0
      description:
      - language: de
        message: 'Maßnahme: Informationsverarbeitende Einrichtungen müssen mit ausreichender Redundanz für die Einhaltung der Verfügbarkeitsanforderungen implementiert werden.'
      - language: en
        message: 'Control: Information processing facilities shall be implemented with redundancy sufficient to meet availability requirements.'
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.17.2.1 Verfügbarkeit von informationsverarbeitenden Einrichtungen
      - language: en
        message: A.17.2.1Availability of information processing facilities
      children: []
      attributes:
        type: Group
        section: b
        structure: A.17.2-0
    attributes:
      type: Group
      section: H1
      structure: A.17-0
  - id: '18'
    type: Section
    title:
    - language: de
      message: A.18 Richtlinienkonformität
    - language: en
      message: A.18 Compliance
    children:
    - id: '1'
      type: Group
      title:
      - language: de
        message: A.18.1 Einhaltung gesetzlicher und vertraglicher Anforderungen
      - language: en
        message: A.18.1 Compliance with legal and contractual requirements
      children:
      - id: 352-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 352-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 352-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 352-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 352-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 354-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 354-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 354-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 354-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 354-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 356-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 356-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 356-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 356-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 356-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 358-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 358-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 358-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 358-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 358-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 360-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 360-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 360-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 360-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 360-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.18.1-0
      description:
      - language: de
        message: 'Maßnahme: KryptographischeMaßnahmen müssen unter Einhaltung aller relevanten Vereinbarungen, Gesetze und Vorschriften angewandt werden.'
      - language: en
        message: 'Control: Cryptographic controls shall be used in compliance with all relevant agreements, legislation and regulations.'
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.18.1.1 Ermittlung anwendbarer gesetzlicher und vertraglicher Anforderungen
      - language: en
        message: A.18.1.1Identification of applicable legislation and contractual requirements
      children: []
      attributes:
        type: Group
        section: b
        structure: A.18.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.18.1.2 Rechte an geistigem Eigentum
      - language: en
        message: A.18.1.2 Intellectual property rights
      children: []
      attributes:
        type: Group
        section: b
        structure: A.18.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.18.1.3 Schutz dokumentierter Informationen
      - language: en
        message: A.18.1.3 Protection of records
      children: []
      attributes:
        type: Group
        section: b
        structure: A.18.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.18.1.4 Privatsphäre und Schutz von personenbezogenen Informationen
      - language: en
        message: 'A.18.1.4: Privacy and protection of personally identifiable information'
      children: []
      attributes:
        type: Group
        section: b
        structure: A.18.1-0
    - id: '1'
      type: Section
      title:
      - language: de
        message: A.18.1.5 Regelung kryptographischerMaßnahmen
      - language: en
        message: A.18.1.5 Regulation of cryptographic controls
      children: []
      attributes:
        type: Group
        section: b
        structure: A.18.1-0
    - id: '2'
      type: Group
      title:
      - language: de
        message: A.18.2 Bewertungen der Informationssicherheit
      - language: en
        message: A.18.2 Information security reviews
      children:
      - id: 363-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 363-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 363-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 363-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 363-surveilance-compendium
        type: Question
        questionType: audit.compendium
      - id: 365-surveilance
        type: Question
        questionType: audit.degree_of_fulfulment
      - id: 365-surveilance-reference_documents
        type: Question
        questionType: audit.reference_documents
      - id: 365-surveilance-audit_evidence
        type: Question
        questionType: audit.audit_evidence
      - id: 365-surveilance-action_items
        type: Question
        questionType: audit.action_items
      - id: 365-surveilance-compendium
        type: Question
        questionType: audit.compendium
      attributes:
        type: Group
        section: h
        structure: A.18.2-0
      description:
      - language: de
        message: >-
          Maßnahme: Vorgesetzte müssen regelmäßig die Konformität der Informationsverarbeitung und der Verfahren in ihrem Zuständigkeitsbereich mit den jeweils anwendbaren Sicherheitspolitiken, Normen und jeglichen sonstigen Sicherheitsanforderungen bewerten.
      - language: en
        message: 'Control: Managers shall regularly review the compliance of information processing and procedures within their area of responsibility with the appropriate security policies, standards and any other security requirements.'
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.18.2.1 Unabhängige Bewertung der Informationssicherheit
      - language: en
        message: 'A.18.2.1 Independent review of: information security'
      children: []
      attributes:
        type: Group
        changes: c
        section: b
        structure: A.18.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.18.2.2 Einhaltung der Sicherheitspolitiken und -normen
      - language: en
        message: 'A.18.2.2: Compliance with security policies and standards'
      children: []
      attributes:
        type: Group
        section: b
        structure: A.18.2-0
    - id: '2'
      type: Section
      title:
      - language: de
        message: A.18.2.3 Inspektion der Technik auf Richtlinienkonformität
      - language: en
        message: 'A.18.2.3 Technical compliance: review'
      children: []
      attributes:
        type: Group
        section: b
        structure: A.18.2-0
    attributes:
      type: Group
      section: H1
      structure: A.18-0
`
    SecuramCatalog._instance = new SecuramCatalog()
    SecuramCatalog._instance.read(ymlLoad(src))
    return SecuramCatalog._instance
  }
}
