import { type I18nContext, type TextMessage } from '../i18n'
import { type ExecutionContext } from '../runtime'
import { type ValidationContext, type Validator } from '../validation'
import { type Evaluator, type EvaluatorValue } from './evaluator'
import { type Fact } from './fact'
import { type Serializable } from './serializable'
export { type Serializable } from './serializable'

// see: https://surveysparrow.com/blog/types-of-survey-questions/
export enum QuestionType {
  // yes/no , radio
  Open,
  Numeric,
  Scale,
  Options,
  Dichotomous,
  Likert,
  Rating,
  Rank,
  Suggestions,
  Date,
  Time,
  DateTime,
  List,
  Complex,
}

export enum ValidatorType {
  Text,
  Numeric,
  Scale,
  Options,
  Date,
  Time,
  DateTime,
  Markdown,
}

export enum TextValidatorType {
  Generic,
  EMail,
  Name,
  Word,
  Uri,
  WebUrl,
}

export enum OptionsValidatorType {
  Generic,
  Numeric,
  YesNo,
  TrueFalse,
}

export enum SectionType {
  Generic,
  Volume,
  Chapter,
  SubChapter,
  Paragraph,
}

export enum ItemType {
  Catalog,
  Page,
  Group,
  Row,
  Matrix,
  Text,
  Question,
  Section,
  Form,
  ComplexValueDescription
}

export interface Hierarchical extends Identifiable, Serializable {
  path: string
  parent: Hierarchical | null
  children: HierarchyObjectCollection<Hierarchical>
  inPath: (path: string) => boolean
  isHierarchyObject: boolean
  addChild: (item: Hierarchical) => void
  referenceId?: string
}

export interface Identifiable {
  id: string
}

export interface HierarchyObjectCollection<T extends Hierarchical > extends Serializable, Map<string, T> {
  getFlatMap: (target?: Map<string, Hierarchical>) => Map<string, Hierarchical>
  add: (path: string, item: T) => void
  read: (o: any, parent?: any) => HierarchyObjectCollection<T>
  forAny: (callbackfn: (value: Hierarchical, key: string, map: Map<string, Hierarchical>) => void, thisArg?: any) => void
}

export type Pathed = Identifiable & Serializable & {
  path: string
}

type itemFunction = <T extends Item> (key: string) => T | undefined

export interface Item extends Serializable, Hierarchical, Pathed {
  title: TextMessage
  type: ItemType
  item: itemFunction
  attributes: Map<string, any>
  referenceCopy: () => Item
  saveFacts: (root?: Item, deepen?: boolean) => any
  loadFacts: (facts: any, flatten?: boolean) => Item
}

export interface Question<T extends EvaluatorValue> extends Item {
  questionType: QuestionType
  /** longer description additonally to the title */
  description?: TextMessage
  /** optional help message  */
  help?: TextMessage
  /** optional placeholder message  */
  placeholder?: TextMessage

  visibleEvaluator?: Evaluator<boolean>
  visible: boolean

  requiredEvaluator?: Evaluator<boolean>
  required: boolean

  readonlyEvaluator?: Evaluator<boolean>
  readonly: boolean

  evaluationId: number
  // a validator to check the given fact
  validator?: Validator

  factEvaluator?: Evaluator<EvaluatorValue>
  fact: Fact<EvaluatorValue>

  getValidator: <T extends Validator>() => T | undefined
  validate: (ctx: ExecutionContext) => ValidationContext
  setFactValue: (val?: T | string, i18nContext?: I18nContext, timestamp?: number) => void
}

export function isItem (object: unknown): object is Item {
  return Object.prototype.hasOwnProperty.call(object, 'itemType')
}

export function isQuestion (object: unknown): object is Question<any> {
  return Object.prototype.hasOwnProperty.call(object, 'questionType')
}
