
import React, { useContext } from 'react'
import { EuiFlexGroup } from '@elastic/eui'
import { AnketaContext } from '../context_provider'
import { type Row } from 'anketa-core'

export interface RowCardProps {
  row: Row
}

export const RowCard = ({ row }: RowCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext)
  if (!row.visible) {
    return <div></div>
  }
  return (
    <EuiFlexGroup wrap={true} key={row.id}>
      {ctx.childrenForItem(row)}
    </EuiFlexGroup>
  )
}
