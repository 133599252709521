import { PATH_SEPARATOR } from './const'
import { PathSegment } from './identifiable'
import { type Hierarchical, type HierarchyObjectCollection, type Identifiable, type Item, type Pathed, type Serializable } from './types'

export function isHierarchyObject (object: unknown): object is HierarchyObject<Hierarchical> {
  return Object.prototype.hasOwnProperty.call(object, 'isHierarchyCollection')
}

export type resolverFunction = (src: any) => Hierarchical

export class HierarchyObject<T extends Hierarchical> extends PathSegment implements Hierarchical, Identifiable, Serializable, Pathed {
  parent: Hierarchical | null = null
  children: HierarchyObjectCollection<Hierarchical>
  readonly isHierarchyObject: boolean = true

  constructor (id: string) {
    super(id)
    this.children = new HierarchyObjectMap()
  }

  static resolver: resolverFunction = (src: any) => {
    throw new Error('No resolver specified')
  }

  public read (o: any): this {
    this.children.clear()
    if (Object.prototype.hasOwnProperty.call(o, 'id')) {
      this._id = o.id
    } else {
      throw new Error('id is missing')
    }
    if (Object.prototype.hasOwnProperty.call(o, 'children')) {
      this.children.read(o.children, this)
      this.children.forEach((value: Hierarchical, key: string) => {
        value.parent = this
      })
    }
    return this
  }

  public save (): any {
    const result = {
      id: this.id
    } as any
    if (this.children.size > 0) {
      result.children = this.children.save()
    }
    return result
  }

  public addChild (item: Hierarchical): void {
    if (item.parent != null) {
      throw new Error("item '" + item.id + "' has alerady a parent")
    }
    this.children.set(item.id, item)
    item.parent = this
  }

  public containsChild (child: T | string): boolean {
    let id: string
    if (typeof (child) === 'string') {
      id = child
    } else {
      id = child.id
    }
    return this.children.has(id)
  }

  public inPath (path: string): boolean {
    return ((this.path === path) || (path.startsWith(this.path + PATH_SEPARATOR)))
  }

  public removeChild (child: T | string): void {
    let id: string
    if (typeof (child) === 'string') {
      id = child
    } else {
      if (child.parent == null) {
        throw new Error('not a child')
      }
      if (child.parent !== this) {
        throw new Error('child of another item')
      }
      id = child.id
    }

    if (!this.children.has(id)) {
      throw new Error('not a child of this')
    }

    if (typeof (child) === 'string') {
      const oldChild = this.children.get(id)
      if (oldChild != null) {
        oldChild.parent = null
      }
    } else {
      child.parent = null
    }
    this.children.delete(id)
  }

  get path (): string {
    let p: Hierarchical | null = this.parent
    let path = this.id
    while (p != null) {
      path = p.id + PATH_SEPARATOR + path
      p = p.parent as T | null
    }
    return path
  }
}

export function isHierarchyObjectCollection (object: unknown): object is HierarchyObjectCollection<Hierarchical> {
  return Object.prototype.hasOwnProperty.call(object, 'isHierarchyCollection')
}

export class HierarchyObjectMap<T extends Hierarchical > implements HierarchyObjectCollection<T> {
  private readonly items: Map<string, T>
  readonly isHierarchyCollection: boolean = true
  t!: new() => T

  constructor () {
    this.items = new Map<string, T>()
  }

  read (o: any, parent?: any): this {
    this.items.clear()
    if (typeof (o) === 'string') {
      o = JSON.parse(o)
    }
    if (!Array.isArray(o)) {
      if (Object.keys(o).length === 0) {
        return this
      }
      throw new Error('invalid data: ' + JSON.stringify(o))
    }
    o.forEach((src: any) => {
      const item = HierarchyObject.resolver(src)
      item.parent = parent
      const unk = item as unknown
      this.set(item.id, unk as T)
    })
    return this
  }

  save (): any {
    const result = new Array<T>()
    this.items.forEach((item: Hierarchical) => {
      result.push(item.save())
    })
    return result
  }

  static load (o: any): HierarchyObjectCollection<Item> {
    if (typeof o === 'string') {
      o = JSON.parse(o)
    }
    const c = new HierarchyObjectMap<Item>()
    c.read(o)
    return c
  }

  clear (): void {
    this.items.clear()
  }

  delete (key: string): boolean {
    return this.items.delete(key)
  }

  getFlatMap (target?: Map<string, Hierarchical>): Map<string, Hierarchical> {
    if (target == null) {
      target = new Map<string, T>()
    }
    this.items.forEach((value: Hierarchical, key: string, map: Map<string, Hierarchical>) => {
      if (isHierarchyObjectCollection(value)) {
        value.children.getFlatMap(target)
      } else {
        const item = value as T
        if (target != null) {
          target.set(item.path, item)
        }
        item.children.getFlatMap(target)
      }
    })
    return target
  }

  forAny (callbackfn: (value: Hierarchical, key: string, map: Map<string, Hierarchical>) => void, thisArg?: any): void {
    const map = this.getFlatMap()
    map.forEach(callbackfn, thisArg)
  }

  forEach (callbackfn: (value: T, key: string, map: Map<string, T>) => void, thisArg?: any): void {
    this.items.forEach(callbackfn, thisArg)
  }

  get (key: string): T | undefined {
    let path = ''
    const i = key.indexOf(PATH_SEPARATOR)
    if (i > -1) {
      path = key.substring(i + 1)
      key = key.substring(0, i)
    }
    const r = this.items.get(key)
    if ((r != null) && path !== '') {
      const v = r.children.get(path)
      if (v != null) {
        return v as T
      }
      return undefined
    }
    return r as T
  }

  add (key: string, item: T): void {
    let path = ''
    const i = key.indexOf(PATH_SEPARATOR)
    if (i > -1) {
      path = key.substring(i + 1)
      key = key.substring(0, i)
    }
    if (path !== '') {
      let r: Hierarchical | undefined
      if (!this.items.has(key)) {
        r = new HierarchyObject(key)
        this.items.set(key, r as T)
      }
      r = this.items.get(key)
      if ((r != null) && path !== '') {
        r.children.add(path, item)
      }
      return
    }
    this.items.set(key, item)
  }

  has (key: string): boolean {
    let path = ''
    const i = key.indexOf(PATH_SEPARATOR)
    if (i > -1) {
      path = key.substring(i + 1)
      key = key.substring(0, i)
    }
    const r = this.items.get(key)
    if ((r != null) && path !== '') {
      return r.children.has(key)
    }
    return this.items.has(key)
  }

  set (key: string, value: T): this {
    if (key.includes(PATH_SEPARATOR)) {
      throw new Error('set can not be used with pathes ("' + key + '")')
    }
    this.items.set(key, value)
    return this
  }

  get size (): number {
    return this.items.size
  }

  get deepSize (): number {
    return this.getFlatMap().size
  }

  entries (): IterableIterator<[string, T]> {
    return this.items.entries()
  }

  keys (): IterableIterator<string> {
    return this.items.keys()
  }

  pathes (): IterableIterator<string> {
    return this.getFlatMap().keys()
  }

  values (): IterableIterator<T> {
    return this.items.values()
  }

  [Symbol.iterator] (): IterableIterator<[string, T]> {
    return this.items[Symbol.iterator]()
  }

  [Symbol.toStringTag]: string = 'ItemCollextion'
}
