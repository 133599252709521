import React, { useContext } from 'react'
import { EuiText, OuiMarkdownFormat } from '@elastic/eui'
import { AnketaContext } from '../context_provider'
import { Section, SectionType } from 'anketa-core'

export interface SectionCardProps {
  section: Section
}

export const SectionCard = ({ section }: SectionCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext)

  if (!section.visible) {
    return <div></div>
  }
  let title
  if (section.sectionType === SectionType.Volume) {
    title = <h2 title={ctx.i18nContext.render(section.help)}>{ctx.i18nContext.render(section.title)}</h2>
  } else if (section.sectionType === SectionType.Chapter) {
    title = <h3 title={ctx.i18nContext.render(section.help)}>{ctx.i18nContext.render(section.title)}</h3>
  } else {
    let parent = section.parent
    let lvl = 1
    while (parent !== null) {
      if ((parent instanceof Section) && (parent.sectionType === SectionType.SubChapter)) {
        lvl++
        if (lvl > 2) {
          break
        }
      }
      parent = parent.parent
    }
    if (lvl > 2) {
      title = <b style={{ fontWeight: 'bolder' }} title={ctx.i18nContext.render(section.help)}>{ctx.i18nContext.render(section.title)}</b>
    } else if (lvl === 1) {
      title = <h6 title={ctx.i18nContext.render(section.help)}>{ctx.i18nContext.render(section.title)}</h6>
    } else {
      title = <h5 title={ctx.i18nContext.render(section.help)}>{ctx.i18nContext.render(section.title)}</h5>
    }
  }

  return (
    <div>
      <EuiText>
        <a href={'#tree-' + section.path} id={section.path}>{title}</a>
        <OuiMarkdownFormat>
          {ctx.i18nContext.render(section.description)}
        </OuiMarkdownFormat>
      </EuiText>
      {ctx.childrenForItem(section)}
  </div>
  )
}
