import React, { useContext } from 'react'
import { EuiMarkdownFormat, getDefaultEuiMarkdownParsingPlugins } from '@elastic/eui'
import { type PluggableList } from 'unified'
import { AnketaContext } from '../context_provider'
import { remarkMermaidPlugin } from '../question_card/mermaid_plugin'
import { remarkSimplePlantumlPlugin } from '../question_card/plantuml_plugin'
import { type TextMessage } from 'anketa-core'

export interface MarkdownTextProps {
  text: TextMessage
}

export function getParserPlugins (key: string): PluggableList {
  const parsingList = getDefaultEuiMarkdownParsingPlugins()
  parsingList.splice(2, 1)
  parsingList.unshift([remarkSimplePlantumlPlugin, {}])
  parsingList.unshift([remarkMermaidPlugin, { key }])
  return parsingList
}

function makeid (length: number): string {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const MarkdownText = ({ text }: MarkdownTextProps): JSX.Element => {
  const ctx = useContext(AnketaContext)
  const id = makeid(32)

  return (
        <EuiMarkdownFormat parsingPluginList={getParserPlugins(id)} key={id}>
            {ctx.i18nContext.render(text)}
        </EuiMarkdownFormat>
  )
}
