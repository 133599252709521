import { parseScript } from 'esprima'
import * as ESTraverse from 'estraverse'
import type * as ESTree from 'estree'
import type jsep from 'jsep'
import { VMScript } from 'vm2'
import { parse } from '../runtime/parser'
import { type ComplexType } from './complex'
import { PATH_SEPARATOR } from './const'
import { type Fact } from './fact'
import { type Serializable } from './serializable'

export type EvaluatorBaseValue = boolean | string | number | Date | ComplexType
export type EvaluatorValue = EvaluatorBaseValue | EvaluatorBaseValue[] | undefined

export type getter<T extends EvaluatorValue> = () => T | undefined
export type setter = (newVal: EvaluatorValue) => void

export class Evaluator<T extends EvaluatorValue> implements Serializable {
  // https://github.com/EricSmekens/jsep
  private _formula!: string
  dependencies!: string[]
  readonly isEvaluator: boolean = true
  _result: EvaluatorValue
  _previousResult?: T
  script!: VMScript | jsep.Expression
  readonly getter: getter<T>
  readonly setter: setter

  constructor (formula: string, getter: getter<T>, setter: setter) {
    formula = formula.trim()
    if (formula === '') {
      throw new Error('formula must not be empty')
    }
    this.getter = getter
    this.setter = setter
    this.formula = formula
  }

  isEvaluatorValue (val: any): boolean {
    if (val === undefined) {
      return true
    }
    if (['number', 'boolean', 'string'].includes(typeof val) || (val instanceof Date)) {
      return true
    }
    return false
  }

  asEvaluatorValue (val: any): EvaluatorValue {
    if (val === undefined) {
      return true
    }
    if (['number', 'boolean', 'string'].includes(typeof val) || (val instanceof Date)) {
      return val
    }
    return undefined
  }

  private parseScript (formula: string): ESTree.Program {
    const parseOptions = {
      jsx: false,
      range: false,
      loc: false,
      tolerant: true,
      tokens: false,
      comment: true
    }
    const ast = parseScript(formula, parseOptions)

    this.dependencies = []
    let curIdentifier = [] as string[]
    const visitor: ESTraverse.Visitor = {
      enter: (node: ESTree.BaseNode, parent: ESTree.BaseNode | null) => {
        // console.log('enter', node.type, parent?.type)
      },
      leave: (node: ESTree.BaseNode, parent: ESTree.BaseNode | null) => {
        if (node.type === 'Identifier') {
          if (parent?.type === 'MemberExpression') {
            curIdentifier.push((node as ESTree.Identifier).name)
          }
        }
        if (node.type.endsWith('Expression')) {
          if (node.type === 'MemberExpression' && parent?.type === 'CallExpression') {
            curIdentifier.pop()
            if (curIdentifier.length > 0) {
              this.dependencies.push(curIdentifier.join(PATH_SEPARATOR))
            }
            curIdentifier = []
          }
          if (parent?.type !== 'MemberExpression') {
            if (curIdentifier.length > 0) {
              this.dependencies.push(curIdentifier.join(PATH_SEPARATOR))
            }
            curIdentifier = []
          }
          // console.log('leave',curIdentifier, node.type, parent?.type)
        }
      }
    }
    ESTraverse.traverse(ast, visitor)
    return ast
  }

  public set formula (formula: string) {
    this._formula = formula
    this.parseScript(formula)
    if (typeof process === 'object') {
      this.script = new VMScript(this.formula, 'evaluator.js')
    } else {
      this.script = parse(this.formula)
    }
  }

  public get formula (): string {
    return this._formula
  }

  public read (o: any): this {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    o = `${o}`
    if ((o.length > 1) && (o[0] === '{') && (o[o.length - 1] === '}')) {
      o = o.substring(1, o.length - 1)
    }
    this.formula = o.trim()
    return this
  }

  public save (): any {
    return '{' + this.formula + '}'
  }

  public toString (): string {
    if (this._result === undefined) {
      return this.formula || ''
    }
    switch (typeof this._result) {
      case 'string': return this._result
      case 'boolean': return this._result ? 'true' : 'false'
      case 'number': return this._result.toString()
    }
    if (this._result instanceof Date) {
      return this._result.toISOString()
    }
    return this.formula || ''
  }
}

export class PropertyEvaluator<T extends EvaluatorValue> extends Evaluator<T> {
  constructor (formula: string, object: any, propertyName: string) {
    const getter = (): T | undefined => {
      return Object.prototype.hasOwnProperty.call(object, propertyName) as T
    }
    const setter = (newVal: EvaluatorValue): void => {
      object[propertyName] = newVal
    }
    super(formula, getter, setter)
  }
}

export class FactEvaluator<T extends EvaluatorValue> extends Evaluator<T> {
  constructor (formula: string, fact: Fact<T>) {
    const getter = (): T => {
      if (this.isEvaluatorValue(fact.raw)) {
        return this.asEvaluatorValue(fact.raw) as T
      }
      throw new Error('Evaluator holds incompatible type')
    }
    const setter = (newVal: EvaluatorValue): void => {
      if (this.isEvaluatorValue(newVal)) {
        fact.raw = this.asEvaluatorValue(newVal)
      } else {
        console.debug('NON-SETTER', newVal, fact.raw, typeof newVal)
      }
    }
    super(formula, getter, setter)
  }
}
