import React, { useContext } from 'react'
import { EuiDescribedFormGroup, EuiFormFieldset, EuiFormRow, EuiTitle } from '@elastic/eui'
import { AnketaContext, EvaluationContext } from '../context_provider'
import { type LikertQuestion, type TextMessage, type Matrix, type Hierarchical } from 'anketa-core'

export interface MatrixCardProps {
  matrix: Matrix
  stripes: boolean
}

export const MatrixCard = ({ matrix, stripes }: MatrixCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext)
  const evalCtx = useContext(EvaluationContext)

  if (!matrix.visible) {
    return <div></div>
  }
  if (matrix.children.size === 0) {
    console.warn('Emtpty matrix', matrix.id)
    return (<div>empty matrix</div>)
  }

  function setValue (question: LikertQuestion, val: string): void {
    question.setFactValue(parseInt(val))
    ctx.reevaluate()
    evalCtx.nextEvaluationId()
    ctx.saveToBrowser()
  }

  function getTable (): JSX.Element {
    const header = new Array<JSX.Element>()
    const items = new Array<JSX.Element>()
    let lineNo: number = 1
    matrix.children.forEach((item: Hierarchical) => {
      const line = new Array<JSX.Element>()
      const q = item as LikertQuestion
      const opts = q.getOptions(ctx)
      const cellWidth = `${75 / opts.size}%`
      let bgColor = '#ffffff'
      if (stripes && (lineNo % 2 === 0)) {
        bgColor = '#F5F7FA'
      }

      if (header.length === 0) {
        header.push(<th key={`${item.path}.l${lineNo}`} style={{ backgroundColor: bgColor, borderBottom: 'solid 1px #F5F7FA' }}>&nbsp;</th>)
        opts.forEach((value: TextMessage, key: string) => {
          header.push(<th style={{ borderBottom: 'solid 1px #F5F7FA', padding: '8px', width: cellWidth, textAlign: 'center', verticalAlign: 'top', backgroundColor: bgColor }} key={item.path + '.k' + key}>&nbsp;{ctx.i18nContext.render(value)}&nbsp;</th>)
        })
      }

      line.push((<td align="left" style={{ minWidth: '110px', marginRight: '60px', textAlign: 'left', verticalAlign: 'middle', backgroundColor: bgColor }} key={item.path}><span>{ctx.i18nContext.render(q.title)}</span></td>))
      opts.forEach((value: TextMessage, key: string) => {
        const selected = (q.fact.toString() === key)
        line.push(<td onClick={ () => {
          const button = document.getElementById(item.path + '.' + key)
          if (button instanceof HTMLElement) {
            button.click()
          }
        }} align="center" style={{ width: cellWidth, textAlign: 'center', verticalAlign: 'middle', backgroundColor: bgColor }} key={item.path + '.' + key}><input type="radio" checked={selected} name={item.path} id={item.path + '.' + key} key={item.path + '.' + key} onChange={(e) => { setValue(q, key) }}></input></td>)
      })
      lineNo++
      items.push(<tr key={`${item.path}.l${lineNo}`}>{line}</tr>)
    })

    const errors = undefined
    const invalid = false
    return (
      <EuiFormRow
        label={ctx.i18nContext.render(matrix.title)}
        helpText={ctx.i18nContext.render(matrix.description)}
        isInvalid={invalid}
        error={errors}>
      <EuiFormFieldset key={matrix.id}>

      <table style={{ margin: 6, padding: 6 }}>
        <thead>
          <tr style={{ backgroundColor: '#FAFBFD' }}>
            {header}
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </table>
      </EuiFormFieldset>
      </EuiFormRow>
    )
  }

  if ((matrix.description) && (matrix.description !== '')) {
    return (
      <EuiDescribedFormGroup
          /* ratio='quarter' */
          title={(<EuiTitle>
          <h4 title={ctx.i18nContext.render(matrix.help)}>Matrix {ctx.i18nContext.render(matrix.title)}</h4>
          </EuiTitle>)}
          fullWidth={false}
          description={<p>{ctx.i18nContext.render(matrix.description)}</p>}>
        {getTable()}
      </EuiDescribedFormGroup>
    )
  }
  return getTable()
}
