import React, { useContext } from 'react'
import { EuiText } from '@elastic/eui'
import { AnketaContext } from '../context_provider'
import { type Text } from 'anketa-core'
import { MarkdownText } from '../markdown_text'
export interface TextCardProps {
  text: Text
}

export const TextCard = ({ text }: TextCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext)

  if (!text.visible) {
    return <div></div>
  }

  return (
    <EuiText title={ctx.i18nContext.render(text.help)}>
      <MarkdownText text={text.title} />
    </EuiText>
  )
}
