import React, { useContext } from 'react'
import { EuiFormFieldset, EuiTitle } from '@elastic/eui'
import { AnketaContext } from '../context_provider'
import { type Group } from 'anketa-core'
import { MarkdownText } from '../markdown_text'

export interface GroupCardProps {
  group: Group
}

export const GroupCard = ({ group }: GroupCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext)

  if (!group.visible) {
    return <div></div>
  }
  let description
  if (group.description) {
    description = <MarkdownText text={group.description}/>
  }
  return (
  <EuiFormFieldset key={group.id}>
    <EuiTitle size='xxxs'>
        <h5 title={ctx.i18nContext.render(group.help)}>{ctx.i18nContext.render(group.title)}</h5>
      </EuiTitle>
    {description}
    {ctx.childrenForItem(group)}
  </EuiFormFieldset>
  )
}
