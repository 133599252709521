import React, { useContext } from 'react'
import { EuiAvatar } from '@elastic/eui'
import { KeycloakContext } from './keycloak'
import gravatar from 'gravatar'

export const Avatar = (): JSX.Element => {
  const ctx = useContext(KeycloakContext)
  const name = ctx.profile?.firstName && ctx.profile.lastName ? `${ctx.profile?.firstName} ${ctx.profile.lastName}` : ctx.profile?.username ? ctx.profile?.username : 'Unknown'
  const img = ctx.profile?.email ? gravatar.url(ctx.profile?.email, { s: '32', format: '404', r: 'pg' }) : ''

  return (
    <EuiAvatar size="m" name={name} imageUrl={img}>
    </EuiAvatar>
  )
}
