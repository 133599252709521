import { ComplexFactory, type ComplexType } from './base/complex'
import { QuestionType, type Item } from './base/types'
import { type TextMessage } from './i18n'
import { QuestionObject } from './question_object'

export class ComplexQuestion extends QuestionObject<ComplexType> {
  complexType: string
  private form?: Item

  constructor (id: string, title: TextMessage, complexType: string) {
    super(id, QuestionType.Complex, title)
    if (!ComplexFactory.isClassRegistered(complexType)) {
      throw new Error('Class ' + complexType + ' is not registered')
    }
    this.complexType = complexType
  }

  public getForm (): Item {
    if (!this.form) {
      const form = ComplexFactory.getForm(this.complexType)
      if ((!form) || (form.children.size === 0)) {
        throw new Error(`Invalid form for complex type ${this.complexType}`)
      }
      this.form = form
    }
    return this.form
  }

  public read (o: any): this {
    super.read(o)
    if (Object.prototype.hasOwnProperty.call(o, 'complexType') && (o.complexType !== undefined)) {
      this.complexType = o.complexType
    }
    if (this.fact?.raw) {
      this.complexType = (this.fact.raw as ComplexType)._complexType
    }

    return this
  }

  public save (): any {
    const result = super.save()
    if (!this.fact.raw) {
      result.complexType = this.complexType
    }
    return result
  }

  public newValue (init?: any): ComplexType {
    this.setFactValue(ComplexFactory.new(this.complexType, init))
    return this.fact.raw as ComplexType
  }
}
