import { type Item } from '../base'
import { ComplexType } from '../base/complex'
import { Form } from '../complex/form'
import { DefaultCatalog } from '../default_catalog'
import { Translation } from '../i18n'
import { cloneItem } from '../item_factory'

export class User extends ComplexType {
  userid!: string
  firstname?: string
  surname?: string

  constructor (init?: any) {
    super('User', init)
  }

  static getForm (): Item {
    const c = new Form('User', [new Translation('en', 'User'), new Translation('de', 'Benutzer')])
    c.addChild(cloneItem(DefaultCatalog.instance, 'personal.email'))
    c.addChild(cloneItem(DefaultCatalog.instance, 'personal.name.firstname'))
    c.addChild(cloneItem(DefaultCatalog.instance, 'personal.name.surname'))
    c.addChild(cloneItem(DefaultCatalog.instance, 'personal.name.title'))
    c.addChild(cloneItem(DefaultCatalog.instance, 'personal.company'))
    c.addChild(cloneItem(DefaultCatalog.instance, 'personal.job_title'))
    return c
  }

  public get fullname (): string {
    if (this.firstname && this.surname) {
      return this.firstname + ' ' + this.surname
    }
    if (this.surname) {
      return '<?> ' + this.surname
    }
    if (this.firstname) {
      return this.firstname + '  <?>'
    }
    return '<? ?>'
  }
}
