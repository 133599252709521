import { type Identifiable, type Serializable } from './types'

export const validId = /^[a-zA-Z0-9]+([a-zA-Z0-9\-_]{0,1}[a-zA-Z0-9]+)*$/
export const validPath = /^([a-zA-Z0-9]+[-_./]?)*[a-zA-Z0-9]+$/

// Base class for everything that has an ID as part of a path
// an id must start and end with an charactor or number any may contain. It may be segmented using dashes and underscores.
// an id may be 32 characters at most.
export class PathSegment implements Identifiable, Serializable {
  _id!: string

  constructor (id: string) {
    this.id = id
  }

  public read (o: any): this {
    if (!Object.prototype.hasOwnProperty.call(o, 'id')) {
      throw new Error("attribute 'id' is missing")
    } else {
      this.id = o.id
    }
    return this
  }

  public save (): any {
    return {
      id: this.id
    }
  }

  get id (): string {
    return this._id
  }

  set id (id: string) {
    if (id === '') {
      throw new Error('id must not be empty')
    }
    if (!validId.test(id)) {
      throw new Error('not a valid id.')
    }
    if (id.length > 32) {
      throw new Error('id is too long.')
    }
    this._id = id
  }
}

// Base class for everything that has an ID in form of a path
// the id must be a combination of path segments
export class Path implements Identifiable, Serializable {
  _id!: string

  constructor (id: string) {
    this.id = id
  }

  public read (o: any): this {
    if (!Object.prototype.hasOwnProperty.call(o, 'id')) {
      if (this._id === '') {
        throw new Error("attribute 'id' is missing")
      }
    } else {
      this.id = o.id
    }
    return this
  }

  public save (): any {
    return {
      id: this.id
    }
  }

  get id (): string {
    return this._id
  }

  set id (id: string) {
    if (id === '') {
      throw new Error('id must not be empty')
    }
    if (!validPath.test(id)) {
      throw new Error('not a valid path.')
    }
    if (id.length > 1024) {
      throw new Error('id is too long.')
    }
    this._id = id
  }

  // path is also always the id
  get path (): string {
    return this._id
  }
}
