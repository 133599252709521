import { ComplexFactory } from './base'
import { CatalogFactory } from './catalog_factory'
import { CatalogObject } from './catalog_object'
import { Link, User } from './complex'
import { ComplexQuestion } from './complex_question'
import { Translation } from './i18n'
import { LikertQuestion } from './likert_question'
import { OpenQuestion } from './open_question'
import { MarkdownValidator } from './validation'

export class AuditCatalog extends CatalogObject {
  private static _instance: AuditCatalog

  public static get instance (): AuditCatalog {
    if (!AuditCatalog._instance) {
      AuditCatalog._instance = new AuditCatalog()
    }
    return AuditCatalog._instance
  }

  static {
    CatalogFactory.registerCatalog({ name: 'audit', instance: AuditCatalog.instance })
  }

  private constructor () {
    super('audit', [new Translation('en', 'Audit Catalog'), new Translation('de', 'Audit Katalog')])
    // register needed complex classes
    ComplexFactory.registerClass({ name: 'Link', Constructor: Link as new (init?: any) => Link, form: Link.getForm })
    ComplexFactory.registerClass({ name: 'User', Constructor: User as new (init?: any) => User, form: User.getForm })
    const qReferenceDocuments = new ComplexQuestion('reference_documents', [new Translation('en', 'reference documents'), new Translation('de', 'Referenz Dokumente')], 'Link')
    this.addChild(qReferenceDocuments)

    const qAuditDocuments = new ComplexQuestion('audit_evidence', [new Translation('en', 'audit evidence'), new Translation('de', 'Audit Nachweise')], 'Link')
    this.addChild(qAuditDocuments)

    const qActionItems = new OpenQuestion('action_items', [new Translation('en', 'Improvements/Action items'), new Translation('de', 'Verbesserungen/Maßnahmen')])
    qActionItems.validator = new MarkdownValidator()
    this.addChild(qActionItems)

    const qCompendium = new OpenQuestion('compendium', [new Translation('en', 'Compendium'), new Translation('de', 'Kompendium')])
    qCompendium.validator = new MarkdownValidator()
    this.addChild(qCompendium)

    const qDegreeOfFulfillment = new LikertQuestion('degree_of_fulfulment', [new Translation('en', 'Degree of Fulfilment'), new Translation('de', 'Erfüllungsgrad')], 'default.options.degree_of_fulfillment_6')
    this.addChild(qDegreeOfFulfillment)
  }
}
