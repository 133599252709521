import React, { useCallback, useContext, useState, useEffect } from 'react'
import { AnketaContext, AnketaContextProvider } from '../context_provider'
import { MessageCollection, type ComplexQuestion, type EvaluatorValue, type Hierarchical, isQuestion, type Link, type Item } from 'anketa-core'
import { EuiButtonEmpty, EuiButtonIcon, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiCode } from '@elastic/eui'
import { CatalogCard } from '../catalog_card'
import { CatalogSubmitButton } from '../catalog_submit_button'
import { ComplexFactory } from 'anketa-core'

export interface ComplexQuestionEditorProps {
  question: ComplexQuestion
  setValue: (val: EvaluatorValue) => void
  invalid: boolean
}

export const ComplexQuestionEditor = ({ question, setValue, invalid }: ComplexQuestionEditorProps): JSX.Element => {
  const [subCatalog, setSubCatalog] = useState<Item>()
  const ctx = useContext(AnketaContext)
  //   let locale = ctx.i18nContext.language
  const [isModalVisible, setIsModalVisible] = useState(false)
  const closeModal = useCallback((): void => { setIsModalVisible(false) }, [])
  const saveModal = useCallback((): void => {
    if (!subCatalog) {
      return
    }
    if (subCatalog.children.size > 0) {
      const rawObject = {} as any
      subCatalog.children.forAny((item: Hierarchical) => {
        if (isQuestion(item)) {
          console.log('complex', item.path, item.fact)
          if (item.fact?.raw) {
            rawObject[item.id] = item.fact?.raw
          }
        }
      })
      const complexVal = ComplexFactory.new(question.complexType, rawObject)
      setValue(complexVal)
    }
    setIsModalVisible(false)
  }, [subCatalog, question.complexType, setValue])

  useEffect((): void => {
    setSubCatalog(question.getForm())
  }, [question])

  const getModalEditor = useCallback((): JSX.Element => {
    return (
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>{ ctx.i18nContext.render(question.title) }</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>
            <EuiModalBody>
                <CatalogCard showTitle={false} />
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal}>{ ctx.i18nContext.render(ctx.messages.get(MessageCollection.cancel)) }</EuiButtonEmpty>
            <CatalogSubmitButton onClick={saveModal}></CatalogSubmitButton>
          </EuiModalFooter>
        </EuiModal>
    )
  }, [ctx.i18nContext, ctx.messages, closeModal, question.title, saveModal])

  let control: JSX.Element
  if (question.complexType === 'Link') {
    if (question.fact.raw) {
      control = (
        <span><a href={(question.fact.raw as Link).url}>{(question.fact.raw as Link).description}</a></span>
      )
    } else {
      control = (
          <span>{(ctx.i18nContext.render(question.placeholder))}</span>
      )
    }
  } else {
    control = (
      <EuiCode language="json" style={{ width: '80%', background: 'white' }} title={question.complexType}>
        { JSON.stringify(question.fact?.raw)}
      </EuiCode>
    )
  }

  let modal = (<></>)
  if (isModalVisible) {
    modal = getModalEditor()
  }

  return (
    <AnketaContextProvider locale={ctx.i18nContext.language} catalog={ subCatalog }>
      <div className='euiFieldText'>
        {modal}
        {control}
        <EuiButtonIcon iconType="documentEdit" display="base" color='text' aria-label={ ctx.i18nContext.render(ctx.messages.get(MessageCollection.edit)) } onClick={() => { setIsModalVisible(true) }} style={{ float: 'right' }}></EuiButtonIcon>
      </div>
    </AnketaContextProvider>
  )
}
