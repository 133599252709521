import { ItemObject } from './base/item_object'
import { ItemType } from './base/types'
import { type TextMessage } from './i18n'
import { type Catalog } from './types'

export class CatalogObject extends ItemObject implements Catalog {
  constructor (id: string, title: TextMessage) {
    super(id, ItemType.Catalog, title)
  }

  public clone (): Catalog {
    const ser = this.save()
    const result = new CatalogObject(this.id, this.title)
    return result.read(ser)
  }
}
