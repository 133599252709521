import React, { type ReactNode, useState, useEffect, useCallback, useMemo, createContext } from 'react'
import Keycloak, { type KeycloakProfile } from 'keycloak-js'
import * as Sentry from '@sentry/react'
import { EuiPageTemplate, EuiLoadingSpinner } from '@elastic/eui'
export type Profile = KeycloakProfile | undefined

export const KeycloakContext = createContext({
  profile: undefined as Profile
})

export interface KeycloakContextProviderProps {
  children: ReactNode
}

export const KeycloakAuth = ({ children }: KeycloakContextProviderProps): JSX.Element => {
  const [profile, _setProfile] = useState<KeycloakProfile | undefined>()

  const setUserInfo = (info: any): void => {
    console.log(info)
    if (info) {
      Sentry.setUser(info)
      Sentry.captureMessage('Session started')
      console.log('userInfo', info)
      _setProfile(info)
    } else {
      Sentry.setUser(null)
      _setProfile(undefined)
    }
  }
  const setProfile = useCallback((profile: Profile): void => {
    const merged = profile ? { ...profile } as any : null
    if (merged) {
      delete merged.userProfileMetadata
      delete merged.attributes
      setUserInfo(merged)
    } else {
      setUserInfo(undefined)
    }
  }, [])

  const keycloak = useMemo(() => {
    const keycloak = new Keycloak({
      url: 'https://keycloak.autonubil.net/auth',
      realm: 'autonubil',
      clientId: 'anketa'
    })
    keycloak.onAuthSuccess = () => {
      console.debug('keycloak', 'Auth Success')
    }

    keycloak.onAuthError = (errorData) => {
      console.warn('keycloak', JSON.stringify(errorData))
    }
    keycloak.onTokenExpired = () => {
      console.debug('keycloak', 'Access token expired.')
    }

    keycloak.onActionUpdate = (status) => {
      switch (status) {
        case 'success':
          console.debug('keycloak', 'Action completed successfully')
          break
        case 'cancelled':
          console.debug('keycloak', 'Action cancelled by user')
          break
        case 'error':
          console.debug('keycloak', 'Action failed')
          break
      }
    }
    return keycloak
  }, [])

  const loadProfile = useCallback((): void => {
    keycloak
      .loadUserProfile()
      .then((profile) => {
        console.debug('[Keycloak] Profile values: ', profile)
        setProfile(profile)
      })
      .catch(function () {
        console.warn('Failed to load profile')
        setProfile(undefined)
        // loadProfile()
      })
  }, [keycloak, setProfile])

  useEffect(() => {
    keycloak
      .init({
        responseMode: 'fragment',
        flow: 'standard',
        enableLogging: true
      })
      .then(async (authenticated) => {
        console.debug('authenticated: ', authenticated)
        console.debug('Init Success (' + (authenticated ? 'Authenticated' : 'Not Authenticated') + ')')
        authenticated ? loadProfile() : await keycloak.login()
      })
      .catch((err) => {
        setProfile(undefined)
        console.warn(err)
      })
  }, [keycloak, loadProfile, setProfile])

  if (profile !== undefined) {
    return (<KeycloakContext.Provider value={{ profile }}>{ children }</KeycloakContext.Provider>)
  }

  return (
    <EuiPageTemplate
      pageContentProps={{ paddingSize: 'none' }}
      pageHeader={{
        pageTitle: 'Authentication required',
        description: 'Redirect to Keycloak...',
        rightSideItems: []
      }}>
      <EuiLoadingSpinner size="xl"/>
    </EuiPageTemplate>

  )
}

/**
     <div style={{ display: 'block' }}>
      <button
        /* eslint-disable @typescript-eslint/no-floating-promises * /
        onClick={() => {
          keycloak.login()
        }}
        /* eslint-enable @typescript-eslint/no-floating-promises * /
      >
        Login
      </button>
      <br />
      <button
        onClick={() => {
          loadProfile()
        }}
      >
        Get Profile
      </button>
      <h2>Result</h2>
      <pre id='output'></pre>

      <h2>Events</h2>
      <pre id='events'></pre>
    </div>
 */
