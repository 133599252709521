import { TextValidatorType } from './base'
import { FactEvaluator, PropertyEvaluator } from './base/evaluator'
import { CatalogFactory } from './catalog_factory'
import { CatalogObject } from './catalog_object'
import { DateQuestion } from './date_question'
import { Group, Matrix, Row } from './group'
import { LikertScale, OptionMap, Translation } from './i18n'
import { LikertQuestion } from './likert_question'
import { NumericQuestion } from './numeric_question'
import { OpenQuestion } from './open_question'
import { OptionsQuestion } from './options_question'
import { SuggestionsQuestion } from './suggestions_question'
import { ScaleValidator, TextValidator } from './validation'

export class DefaultCatalog extends CatalogObject {
  private static _instance: DefaultCatalog

  public static get instance (): DefaultCatalog {
    if (!DefaultCatalog._instance) {
      DefaultCatalog._instance = new DefaultCatalog()
    }
    return DefaultCatalog._instance
  }

  static {
    CatalogFactory.registerCatalog({ name: 'default', instance: DefaultCatalog.instance })
  }

  private constructor () {
    super('default', [new Translation('en', 'Default Catalog'), new Translation('de', 'Default Katalog')])

    const gPersonal = new Group('personal', [new Translation('en', 'Personal data'), new Translation('de', 'Persönliche Daten')])
    gPersonal.help = [new Translation('en', 'Basic data about you.'), new Translation('de', 'Persönliche Daten')]
    // gPersonal.description = [new Translation('en', 'Demographic data'), new Translation('de', 'Demographische Daten')]
    this.addChild(gPersonal)

    const rName = new Row('name', [new Translation('en', 'Name'), new Translation('de', 'Name')])
    gPersonal.addChild(rName)

    const qSurame = new OpenQuestion('firstname', [new Translation('en', 'Firstname'), new Translation('de', 'Vorname')])
    qSurame.validator = new TextValidator(TextValidatorType.Name)
    qSurame.placeholder = [new Translation('en', 'surname'), new Translation('de', 'Vorname')]
    qSurame.description = [new Translation('en', 'Your surname'), new Translation('de', 'Ihr Vorname')]
    qSurame.help = [new Translation('en', 'Please provide your surname'), new Translation('de', 'Bitte ihren Vornamen eingeben')]
    rName.addChild(qSurame)

    const qName = new OpenQuestion('surname', [new Translation('en', 'Name'), new Translation('de', 'Nachname')])
    qName.validator = new TextValidator(TextValidatorType.Name)
    qName.placeholder = [new Translation('en', 'last name'), new Translation('de', 'Nachname')]
    qName.description = [new Translation('en', 'Your last name'), new Translation('de', 'Ihr Nachname')]
    qName.help = [new Translation('en', 'Please provide your last name'), new Translation('de', 'Bitte ihren Nachnamen eingeben')]
    rName.addChild(qName)

    const qTitle = new SuggestionsQuestion('title', [new Translation('en', 'Title'), new Translation('de', 'Titel')], OptionMap.titles)
    qTitle.required = false
    rName.addChild(qTitle)

    const qCompany = new OpenQuestion('company', [new Translation('en', 'Company'), new Translation('de', 'Firma')])
    gPersonal.addChild(qCompany)

    const qJobTitle = new SuggestionsQuestion('job_title', [new Translation('en', 'Job Title'), new Translation('de', 'Position')], OptionMap.job_titles)
    qJobTitle.required = false
    gPersonal.addChild(qJobTitle)

    const qEMail = new OpenQuestion('email', [new Translation('en', 'eMail Address'), new Translation('de', 'E-Mail Adressese')])
    qEMail.validator = new TextValidator(TextValidatorType.EMail)
    gPersonal.addChild(qEMail)

    const rGender = new Row('gender', [new Translation('en', 'Gender'), new Translation('de', 'Gender')])
    gPersonal.addChild(rGender)

    const qSex = new OptionsQuestion('sex', [new Translation('en', 'Gender'), new Translation('de', 'Geschlecht')], OptionMap.sex)
    rGender.addChild(qSex)

    const qGenderLong = new SuggestionsQuestion('gender_long', [new Translation('en', 'Identity'), new Translation('de', 'Identität')], OptionMap.gender_identity)
    // qPronoun.setFactValue(new Evaluator<boolean>('personal.gender.value === "diverse"'))
    qGenderLong.visibleEvaluator = new PropertyEvaluator<boolean>('personal.gender.sex.value === "diverse"', qGenderLong, 'visible')
    rGender.addChild(qGenderLong)

    const qPronoun = new SuggestionsQuestion('pronoun', [new Translation('en', 'Pronoun'), new Translation('de', 'Pronomen')], OptionMap.pronoun)
    // qPronoun.setFactValue(new Evaluator<boolean>('personal.gender.value === "diverse"'))
    qPronoun.visibleEvaluator = new PropertyEvaluator<boolean>('personal.gender.sex.value === "diverse"', qPronoun, 'visible')
    rGender.addChild(qPronoun)

    const qCountry = new OptionsQuestion('country', [new Translation('en', 'Country of birth'), new Translation('de', 'Geburtsland')], OptionMap.country)
    gPersonal.addChild(qCountry)

    const qBirthday = new DateQuestion('birthday', [new Translation('en', 'Birthday'), new Translation('de', 'Geburtsdatum')])
    gPersonal.addChild(qBirthday)

    const qAge = new NumericQuestion('age', [new Translation('en', 'Age'), new Translation('de', 'Alter')])
    qAge.validator = new ScaleValidator(6, 130)
    qAge.readonly = true
    qAge.visibleEvaluator = new PropertyEvaluator<boolean>('personal.birthday.value !== undefined', qAge, 'visible')
    qAge.factEvaluator = (new FactEvaluator<number>('now.getFullYear() - personal.birthday.value.getFullYear()', qAge.fact))
    gPersonal.addChild(qAge)

    const gSatisfaction = new Matrix('satisfaction', [new Translation('en', 'Satisfaction'), new Translation('de', 'Zufriedenheit')])
    gSatisfaction.help = [new Translation('en', 'Your satisfaction with the service.'), new Translation('de', 'Zufriedenheit mit der Dienstleistung')]
    this.addChild(gSatisfaction)
    const qAllCool = new LikertQuestion('all_cool', [new Translation('en', 'Anketa is cool'), new Translation('de', 'Anketa ist töfte')], LikertScale.agreement_5)
    gSatisfaction.addChild(qAllCool)

    const qLikeAnswer = new LikertQuestion('like_ansers', [new Translation('en', 'I like answering questions'), new Translation('de', 'Ich beantworte gerne Fragen')], LikertScale.agreement_5)
    gSatisfaction.addChild(qLikeAnswer)

    const gTyped = new Group('typed', [new Translation('en', 'Typed Data'), new Translation('de', 'Typisierte Daten')])
    this.addChild(gTyped)
    const qUri = new OpenQuestion('uri', [new Translation('en', 'URI'), new Translation('de', 'URI')])
    qUri.validator = new TextValidator(TextValidatorType.Uri)
    qUri.placeholder = [new Translation('en', 'URI'), new Translation('de', 'URI')]
    qUri.description = [new Translation('en', 'URI'), new Translation('de', 'URI')]
    qUri.help = [new Translation('en', 'Please provide a valid URI'), new Translation('de', 'Bitte eine URI angeben')]
    gTyped.addChild(qUri)
    const qWebUrl = new OpenQuestion('webUrl', [new Translation('en', 'Web URL'), new Translation('de', 'Web URL')])
    qWebUrl.validator = new TextValidator(TextValidatorType.WebUrl)
    qWebUrl.placeholder = [new Translation('en', 'URL'), new Translation('de', 'URL')]
    qWebUrl.description = [new Translation('en', 'Web URL'), new Translation('de', 'Web URL')]
    qWebUrl.help = [new Translation('en', 'Link to a web resource'), new Translation('de', 'Link auf eine Resource im Web')]
    gTyped.addChild(qWebUrl)
    const qDescription = new OpenQuestion('description', [new Translation('en', 'description'), new Translation('de', 'Beschreibung')])
    qDescription.validator = new TextValidator(TextValidatorType.Generic)
    qDescription.placeholder = [new Translation('en', 'description'), new Translation('de', 'Beschreibung')]
    qDescription.description = [new Translation('en', 'description'), new Translation('de', 'Beschreibung')]
    gTyped.addChild(qDescription)

    const gComplex = new Group('complex', [new Translation('en', 'Complex Data'), new Translation('de', 'Komplexe Daten')])
    // gPersonal.description = [new Translation('en', 'Demographic data'), new Translation('de', 'Demographische Daten')]
    this.addChild(gComplex)
  }
}
