import { HierarchyObject, isQuestion } from './base'
import { ComplexFactory } from './base/complex/complex_factory'
import { type EvaluatorBaseValue } from './base/evaluator'
import { ItemType, QuestionType, type Item } from './base/types'
import { CatalogFactory } from './catalog_factory'
import { CatalogObject } from './catalog_object'
import { Generic } from './complex/generic'
import { ComplexQuestion } from './complex_question'
import { DateQuestion } from './date_question'
import { Group, Matrix, Page, Row, Section } from './group'
import { LikertQuestion } from './likert_question'
import { ListQuestion } from './list_question'
import { NumericQuestion } from './numeric_question'
import { OpenQuestion } from './open_question'
import { OptionsQuestion } from './options_question'
import { type QuestionObject } from './question_object'
import { ScaleQuestion } from './scale_question'
import { SuggestionsQuestion } from './suggestions_question'
import { Text } from './text'

export function itemFromAny (src: any): Item {
  if (!Object.prototype.hasOwnProperty.call(src, 'type')) {
    console.warn(src)
    throw new Error('Item has no type')
  }
  let typ: ItemType
  if (typeof src.type === 'string') {
    typ = (ItemType[src.type] as any) as ItemType
  } else {
    typ = src.type as ItemType
  }
  switch (typ) {
    case ItemType.Catalog: {
      const cat = new CatalogObject('t_m_p', 'import')
      return cat.read(src)
    }
    case ItemType.Section: {
      const grp = new Section('t_m_p', 'import')
      return grp.read(src)
    }
    case ItemType.Group: {
      const grp = new Group('t_m_p', 'import')
      return grp.read(src)
    }
    case ItemType.Row: {
      const grp = new Row('t_m_p', 'import')
      return grp.read(src)
    }
    case ItemType.Page: {
      const grp = new Page('t_m_p', 'import')
      return grp.read(src)
    }
    case ItemType.Text: {
      const txt = new Text('t_m_p', 'import')
      return txt.read(src)
    }
    case ItemType.Question: {
      return questionFromAny(src)
    }
    case ItemType.Matrix: {
      const grp = new Matrix('t_m_p', 'import')
      return grp.read(src)
    }
  }
  throw new Error(`unsupported item type '${typ}'('${ItemType[src.type]}')`)
}

export function cloneItem (item: Item, path?: string, includeValue?: boolean): Item {
  if (path) {
    item = item.item(path) as Item
    if (!item) {
      throw new Error('Item "' + path + '" not found')
    }
  }

  const ser = item.save()
  const result = itemFromAny(ser)
  // result.read(ser)
  if (isQuestion(result) && (includeValue !== false)) {
    if (result.fact?.timestamp > 0) {
      result.setFactValue(undefined, undefined, 0)
    }
  }
  return result
}

export function questionFromAny (src: any): QuestionObject<EvaluatorBaseValue> {
  if (!Object.prototype.hasOwnProperty.call(src, 'questionType')) {
    throw new Error('no questionType specified')
  }

  let qt: QuestionType
  if (typeof (src.questionType) === 'string') {
    qt = (QuestionType[src.questionType] as any) as QuestionType
  } else {
    qt = src.questionType as QuestionType
  }
  switch (qt) {
    case QuestionType.Open as QuestionType: {
      const q = new OpenQuestion('t_m_p', 'tmp')
      q.read(src)
      return q
    }
    case QuestionType.Suggestions as QuestionType: {
      const q = new SuggestionsQuestion('t_m_p', 'tmp', '')
      q.read(src)
      return q
    }
    case QuestionType.Numeric as QuestionType: {
      const q = new NumericQuestion('t_m_p', 'tmp')
      q.read(src)
      return q
    }
    case QuestionType.Options as QuestionType: {
      const q = new OptionsQuestion('t_m_p', 'tmp', '')
      q.read(src)
      return q
    }
    case QuestionType.Scale as QuestionType: {
      const q = new ScaleQuestion('t_m_p', 'tmp')
      q.read(src)
      return q
    }
    case QuestionType.Date as QuestionType: {
      const q = new DateQuestion('t_m_p', 'tmp')
      q.read(src)
      return q
    }
    case QuestionType.Likert as QuestionType: {
      const q = new LikertQuestion('t_m_p', 'tmp', '')
      q.read(src)
      return q
    }
    case QuestionType.List as QuestionType: {
      const q = new ListQuestion('t_m_p', 'tmp')
      q.read(src)
      return q
    }
    case QuestionType.Complex as QuestionType: {
      ComplexFactory.registerClass({ name: 'Generic', Constructor: Generic as new (init?: any) => Generic, form: Generic.getForm })
      const q = new ComplexQuestion('t_m_p', 'tmp', 'Generic')
      q.read(src)
      return q
    }
  }

  // question by reference?
  if (src.questionType.indexOf('.') > 0) {
    const parts = src.questionType.split('.')
    const catalogId = parts[0]
    const catalog = CatalogFactory.instance(catalogId)
    if (!catalog) {
      throw new Error(`unknown catalog for question type ${src.questionType as string}`)
    }
    const itemId = parts.slice(1).join('.')
    const item = catalog.item(itemId)
    if (!item) {
      throw new Error(`unresolved refernced question type ${src.questionType as string}`)
    }
    const ser = item.save()
    return questionFromAny(ser)
  }

  throw new Error(`unsupported question type ${src.questionType as string}`)
}

HierarchyObject.resolver = itemFromAny
