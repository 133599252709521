import { I18nContext } from '../i18n/message_context'
import { type Evaluator, type EvaluatorValue } from './evaluator'
import { Fact } from './fact'

export type FactValue = EvaluatorValue | Evaluator<EvaluatorValue> | undefined

export class NumberFact extends Fact<number> {
  public setValue (val?: string | number, i18nContext?: I18nContext): void {
    if (val && (typeof val === 'string')) {
      const ctx = i18nContext ?? I18nContext.Global
      val = ctx.parseFloat(val)
    }
    if (this.raw !== val) {
      this.raw = val
      this.timestamp = Date.now()
    }
  }
}

export class IntegerFact extends Fact<number> {
  public setValue (val?: string | number, i18nContext?: I18nContext): void {
    if (val && (typeof val === 'string')) {
      const ctx = i18nContext ?? I18nContext.Global
      val = ctx.parseInt(val)
    }
    if (this.raw !== val) {
      this.raw = val
      this.timestamp = Date.now()
    }
  }
}

export class DateFact extends Fact<Date> {
  public setValue (val?: string | Date, i18nContext?: I18nContext): void {
    if (val && (typeof val === 'string')) {
      const ctx = i18nContext ?? I18nContext.Global
      val = ctx.parseDate(val)
    }
    if (this.raw !== val) {
      this.raw = val
      this.timestamp = Date.now()
    }
  }
}
