import moment, { type Moment } from 'moment'
import { type I18nContext } from '../i18n/message_context'
import { Evaluator, type EvaluatorValue } from './evaluator'
import { type Serializable } from './serializable'

export type FactValue = EvaluatorValue | Evaluator<EvaluatorValue> | undefined

export class Fact<T extends EvaluatorValue> implements Serializable {
  raw: FactValue
  timestamp: number

  constructor (val?: T) {
    if (val && ((val !== Object(val)) || (val instanceof Date) || (Array.isArray(val)) || (Object.prototype.hasOwnProperty.call(val, 'toString') && val.toString() !== ''))) {
      this.raw = val
      this.timestamp = Date.now()
    } else {
      this.timestamp = 0
    }
  }

  public setValue (val?: T | string, i18nContext?: I18nContext, timestamp?: number): void {
    if (this.raw !== val) {
      this.raw = val
      this.timestamp = timestamp ?? Date.now()
    }
  }

  public read (o: any): this {
    if (o === null || o === undefined) {
      return this
    }
    if (Object.prototype.hasOwnProperty.call(o, 'value')) {
      if (typeof o.value === 'string') {
        try {
          const asDate = moment(o.value, true)
          if (asDate.isValid()) {
            this.raw = asDate.toDate()
          } else {
            this.raw = o.value
          }
        } catch {
          this.raw = o.value
        }
      } else {
        this.raw = o.value
      }
    }
    if (Object.prototype.hasOwnProperty.call(o, 'timestamp') && (o.timestamp !== undefined)) {
      this.timestamp = o.timestamp
    }
    return this
  }

  public save (): any {
    if (this.raw === undefined) {
      return null
    }
    let val = this.raw
    if (val instanceof Date) {
      val = '' + val.toISOString()
    }
    return {
      value: val,
      timestamp: this.timestamp
    }
  }

  public toString (): string {
    if ((this.raw === undefined) || (this.raw === null)) {
      return ''
    }
    if (Array.isArray(this.raw)) {
      return this.raw.join(', ')
    }
    if (this.raw instanceof Evaluator) {
      this.raw.toString()
    }
    if (typeof this.raw === 'string') {
      return this.raw
    }
    if (typeof this.raw === 'number') {
      return this.raw.toString()
    }
    if (this.raw instanceof Date) {
      return this.raw.toISOString()
    }
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    if (Object.prototype.hasOwnProperty.call(this.raw, 'toString')) {
      return this.raw.toString()
    }
    return 'N/A'
  }

  public getDateValue (i18nContext: I18nContext): Date {
    return i18nContext.parseDate(this.render(i18nContext))
  }

  public getMomentValue (i18nContext: I18nContext): Moment {
    if (this.raw instanceof Date) {
      return moment(this.raw)
    }
    return i18nContext.parseMoment(this.render(i18nContext))
  }

  public getFloatValue (i18nContext: I18nContext): number {
    return i18nContext.parseFloat(this.render(i18nContext))
  }

  public getIntValue (i18nContext: I18nContext): number {
    return i18nContext.parseInt(this.render(i18nContext))
  }

  public getStrValue (i18nContext: I18nContext): string {
    return this.render(i18nContext)
  }

  render (ctx: I18nContext): string {
    return ctx.render(this.raw)
  }
}
