import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import '@elastic/eui/dist/eui_theme_amsterdam_light.css'

// Make sure all locales are loaded
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/pt'
import 'moment/locale/es'
import 'moment/locale/pl'
import 'moment/locale/ru'
import 'moment/locale/nl'
import 'moment/locale/en-gb'
import 'moment/locale/de-ch'
import 'moment/locale/de-at'

Sentry.init({
  dsn: 'https://2c8b22ca99e74eb1bcfbf72589590eaf@sentry.genesis.exanio.cloud/21',
  integrations: [new BrowserTracing()],
  environment: (location.hostname === 'localhost' || location.hostname === '127.0.0.1') ? 'Development' : 'Production',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

ReactDOM.render(<App />, document.getElementById('root'))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
