import './App.css'
import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import { KeycloakAuth, Avatar } from './auth'

import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageSideBar,
  useEuiI18n
} from '@elastic/eui'

import { AnketaContextProvider, CatalogCard } from './components'
import { APP_NAME } from './common/constant'
import { CatalogTree } from './components/catalog_tree'
import { SecuramCatalog } from './securam'
import { Bsi2023Catalog } from './bsi2023'
import { type Catalog, TestCatalog } from 'anketa-core'
// import { EuiResizablePanel } from '@elastic/eui/src/components/resizable_container/resizable_panel'

function App (): JSX.Element {
  /* Bsi2023Catalog.instance */
  const [language, _setLanguage] = useState('de-de')
  const [catalog, _setCatalog] = useState(TestCatalog.instance.clone())
  const [toggleIdToSelectedMap, setToggleIdToSelectedMap] = useState({ default: true } as any)
  const [toggleIdToSelectedLanguageMap, setToggleIdToSelectedLanguageMap] = useState({ 'de-de': true } as any)

  const updateSentryContext = (): void => {
    Sentry.setContext('anketa', {
      locale: language,
      catalog: catalog ? catalog.id : 'N/A'
    })
  }

  const setCatalog = (catalog: Catalog): void => {
    _setCatalog(catalog)
    updateSentryContext()
  }
  const setLanguage = (lang: string): void => {
    _setLanguage(lang)
    setToggleIdToSelectedLanguageMap({ [lang]: true })
    updateSentryContext()
  }

  const FallbackComponent = (): JSX.Element => {
    return (
      <div>An error has occured</div>
    )
  }

  const catalogButtons = [
    {
      id: 'test',
      label: 'Test'
    },
    {
      id: 'bsi2023',
      label: 'BSI 2023'
    },
    {
      id: 'securam',
      label: 'Securam'
    }
  ]

  const languageButtons = [
    {
      id: 'en-us',
      label: useEuiI18n('euiContext.english', 'English')
    },
    {
      id: 'de-de',
      label: useEuiI18n('euiContext.german', 'Deutsch')
    }
  ]

  const onSelectCatalog = (catalogId: string): void => {
    console.log('Catalog ID', catalogId)
    const newToggleIdToSelectedMap = {
      [catalogId]: true
    }
    setToggleIdToSelectedMap(newToggleIdToSelectedMap)
    switch (catalogId) {
      case 'test':
        setCatalog(TestCatalog.instance.clone())
        break
      case 'bsi2023':
        setCatalog(Bsi2023Catalog.instance.clone())
        break
      case 'securam':
        setCatalog(SecuramCatalog.instance.clone())
        break
    }
  }

  return (
    <EuiPage>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <KeycloakAuth>
        <AnketaContextProvider locale={language} catalog={catalog}>
          <EuiPageSideBar paddingSize="l" sticky style={{ minWidth: '30em', width: '35em' }}>
            <CatalogTree></CatalogTree>
          </EuiPageSideBar>
          <EuiPageBody component="div" className="oui-fullHeight">
            <EuiPageHeader>
              <EuiPageHeaderSection>
                <EuiTitle size="l">
                  <h1>{APP_NAME}</h1>
                </EuiTitle>
              </EuiPageHeaderSection>
              <EuiPageHeaderSection>
                <EuiFlexGroup gutterSize="s" alignItems="center">
                  <EuiFlexItem>
                    <EuiButtonGroup
                      options={catalogButtons}
                      onChange={(id: string) => {
                        onSelectCatalog(id)
                      }}
                      legend="Catalog"
                      color="primary"
                      idToSelectedMap={toggleIdToSelectedMap}
                      type="multi"
                    ></EuiButtonGroup>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                  <EuiButtonGroup
                    options={languageButtons}
                    legend="Language"
                    type="multi"
                    idToSelectedMap={toggleIdToSelectedLanguageMap}
                    onChange={(id: string) => {
                      setLanguage(id)
                    }}>
                    </EuiButtonGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <Avatar></Avatar>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPageHeaderSection>
            </EuiPageHeader>
            <CatalogCard />
          </EuiPageBody>
        </AnketaContextProvider>
      </KeycloakAuth>
      </Sentry.ErrorBoundary>
    </EuiPage>
  )
}

export default App

/**
<EuiContext i18n={i18n}>
<Overview></Overview>
</EuiContext>
 */
