import React, { type MouseEventHandler, useContext, useState, useEffect } from 'react'
import { EuiButton } from '@elastic/eui'
import { AnketaContext, EvaluationContext } from '../context_provider'
import { type Hierarchical, MessageCollection, isQuestion } from 'anketa-core'

export interface CatalogSubmitButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const CatalogSubmitButton = ({ onClick }: CatalogSubmitButtonProps): JSX.Element => {
  const ctx = useContext(AnketaContext)
  const evalCtx = useContext(EvaluationContext)
  const [valid, setValid] = useState(ctx.catalog.children.size > 0)

  useEffect(() => {
    let newValid = ctx.catalog.children.size > 0
    ctx.catalog.children.forAny((item: Hierarchical) => {
      if (isQuestion(item)) {
        const testCtx = item.validate(ctx)
        if (testCtx.hasError) {
          newValid = false
        }
      }
    })
    if (valid !== newValid) {
      setValid(newValid)
    }
  }, [ctx, ctx.catalog.children, valid, evalCtx.evaluationId])

  return (
  <EuiButton disabled={!valid} onClick={onClick} fill>
    { ctx.i18nContext.render(ctx.messages.get(MessageCollection.save)) }
  </EuiButton>
  )
}
