import React, { useContext } from 'react'
import { EuiTreeView } from '@elastic/eui'
import { type Node } from '@elastic/eui/src/components/tree_view/tree_view'
import { AnketaContext, EvaluationContext } from '../context_provider'
import { type Hierarchical, type Item, ItemType, type Section } from 'anketa-core'
import { ItemObject } from 'anketa-core'

export const CatalogTree = (props: any): JSX.Element => {
  const ctx = useContext(AnketaContext)
  const evalCtx = useContext(EvaluationContext)

  const childrenForItem = (item: Item): Node[] => {
    const result = new Array<Node>()
    item.children.forEach((child: Hierarchical) => {
      if (child instanceof ItemObject) {
        if (child.type === ItemType.Group) {
          // .push(<GroupCard key={child.path} group={child as Group}></GroupCard>)
        }
        if (child.type === ItemType.Section) {
          const section = child as Section
          if (!section.visible || (section.navVisible !== undefined && !section.navVisible)) {
            return
          }
          const node: Node = {
            id: 'tree-' + section.path,
            label: ctx.i18nContext.render(section.title),
            isExpanded: section.expanded,
            callback: (): string => {
              evalCtx.setFocus(child.path)
              window.location.href = '#' + child.path
              return child.path
            }
          }
          const children = childrenForItem(section)
          if (children.length > 0) {
            node.children = children
          }
          result.push(node)
        }
      }
    })
    return result
  }

  return (
    <EuiTreeView
      display={'compressed'}
      showExpansionArrows
      expandByDefault={false}
      aria-label={ctx.i18nContext.render(ctx.catalog.title)}
      items={childrenForItem(ctx.catalog)}
      />
  )
}
