import React, { type SyntheticEvent, useContext, useState } from 'react'
import { EuiDatePicker } from '@elastic/eui'
import { AnketaContext } from '../context_provider'
import { type DateValidator, type DateQuestion, type EvaluatorValue } from 'anketa-core'
import moment, { type Moment } from 'moment'

export interface DateQuestionEditorProps {
  question: DateQuestion
  setValue: (val: EvaluatorValue, reevaluate?: boolean) => void
  invalid: boolean
}

export const DateQuestionEditor = ({ question, setValue, invalid }: DateQuestionEditorProps): JSX.Element => {
  const ctx = useContext(AnketaContext)
  let locale = ctx.i18nContext.language
  const dfAny = ctx.i18nContext.dateFormat as any
  if (Object.prototype.hasOwnProperty.call(dfAny, '_abbr')) {
    locale = dfAny._abbr
  }
  const validator = question.getValidator<DateValidator>()
  const min = (validator?.min) ? moment(question.getValidator<DateValidator>()?.min) : undefined
  const max = (validator?.max) ? moment(question.getValidator<DateValidator>()?.max) : undefined
  const val = question.fact.getMomentValue(ctx.i18nContext).isValid() ? question.fact.getMomentValue(ctx.i18nContext).format(ctx.i18nContext.dateFormat.longDateFormat('L')) : undefined
  const [curDate, setCurDate] = useState(val)

  return (
    <EuiDatePicker
      key={question.path}
      placeholder={ctx.i18nContext.render(question.placeholder)}
      isInvalid={invalid}
      readOnly={question.readonly}
      locale={locale}
      minDate={min}
      maxDate={max}
      value={val}
      onChange={(date: Moment, event?: SyntheticEvent<any, Event>) => { setCurDate(date.format(ctx.i18nContext.dateFormat.longDateFormat('L'))); setValue(date.toDate()) }}
      onBlur={() => { setValue(curDate, true) }}
    />
  )
}
