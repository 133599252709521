import { FactEvaluator, PropertyEvaluator, type Evaluator, type EvaluatorValue } from './base/evaluator'
import { Fact } from './base/fact'
import { ItemObject } from './base/item_object'
import { ItemType, QuestionType, type Question } from './base/types'
import { readTextMessage, saveTextMessage, type I18nContext, type TextMessage } from './i18n'
import { type ExecutionContext } from './runtime'
import { TextValidator, ValidationContext, validatorFromAny, type Validator } from './validation'

export abstract class QuestionObject<T extends EvaluatorValue> extends ItemObject implements Question<T> {
  static readonly DEFAULT_VALIDATOR = new TextValidator()
  questionType: QuestionType
  /** longer description additonally to the title */
  description?: TextMessage
  /** optional help message  */
  help?: TextMessage
  /** optional placeholder message  */
  placeholder?: TextMessage

  visibleEvaluator?: Evaluator<boolean>
  visible: boolean = true

  requiredEvaluator?: Evaluator<boolean>
  required: boolean = true

  readonlyEvaluator?: Evaluator<boolean>
  readonly: boolean = false

  evaluationId: number = 0
  // a validator to check the given fact
  _validator?: Validator

  factEvaluator?: Evaluator<T>
  private _fact: Fact<T>

  constructor (id: string, type: QuestionType, title: TextMessage) {
    super(id, ItemType.Question, title)
    this.questionType = type
    this._fact = new Fact<T>()
    this.visible = true
  }

  public read (o: any): this {
    if (!o) {
      throw new Error('cann not read question from undefined')
    }
    super.read(o);
    ['questionType'].forEach((required: string) => {
      if (!Object.prototype.hasOwnProperty.call(o, required)) {
        throw new Error("missing '" + required + "' attribute")
      }
    })
    if (typeof (o.questionType) === 'string') {
      this.questionType = (QuestionType[o.questionType] as any) as QuestionType
    } else {
      this.questionType = o.questionType as QuestionType
    }

    if (Object.prototype.hasOwnProperty.call(o, 'validator') && (o.validator !== undefined)) {
      this.validator = validatorFromAny(o.validator)
    }

    if (Object.prototype.hasOwnProperty.call(o, 'help') && (o.help !== undefined)) {
      this.help = readTextMessage(o.help, this.path)
    }
    if (Object.prototype.hasOwnProperty.call(o, 'placeholder') && (o.placeholder !== undefined)) {
      this.placeholder = readTextMessage(o.placeholder, this.path)
    }
    if (Object.prototype.hasOwnProperty.call(o, 'description') && (o.description !== undefined)) {
      this.description = readTextMessage(o.description, this.path)
    }

    if (Object.prototype.hasOwnProperty.call(o, 'visible') && (o.visible !== undefined)) {
      if (typeof o.visible === 'boolean') {
        this.visible = o.visible
      } else {
        if (this.visibleEvaluator === undefined) {
          this.visibleEvaluator = new PropertyEvaluator<boolean>('true', this, 'visible')
        }
        this.visibleEvaluator = this.visibleEvaluator.read(o.visible)
      }
    }

    if (Object.prototype.hasOwnProperty.call(o, 'required') && (o.required !== undefined)) {
      if (typeof o.required === 'boolean') {
        this.required = o.required
      } else {
        if (this.requiredEvaluator === undefined) {
          this.requiredEvaluator = new PropertyEvaluator<boolean>('true', this, 'required')
        }
        this.requiredEvaluator = this.requiredEvaluator.read(o.required)
      }
    }

    if (Object.prototype.hasOwnProperty.call(o, 'readonly') && (o.readonly !== undefined)) {
      if (typeof o.readonly === 'boolean') {
        this.readonly = o.readonly
      } else {
        if (this.readonlyEvaluator === undefined) {
          this.readonlyEvaluator = new PropertyEvaluator<boolean>('false', this, 'readonly')
        }
        this.readonlyEvaluator = this.readonlyEvaluator.read(o.readonly)
      }
    }

    if (Object.prototype.hasOwnProperty.call(o, 'fact') && (o.fact !== undefined)) {
      if ((o.fact.length > 1) && (o.fact[0] === '{') && (o.fact[o.fact.length - 1] === '}')) {
        const ev = new FactEvaluator<T>('true', this.fact)
        ev.read(o.fact)
        this.factEvaluator = ev
      } else {
        this._fact = this.fact.read(o.fact)
      }
    }

    /*
    if (Object.prototype.hasOwnProperty.call(o, 'factEvaluator') && (o.factEvaluator !== undefined)) {
      const ev = new FactEvaluator('true', this.fact)
      ev.read(o.factEvaluator)
      this.factEvaluator = ev
    }
  */
    return this
  }

  public save (): any {
    const result = super.save()
    result.questionType = QuestionType[this.questionType]
    if (this.help !== undefined) {
      result.help = saveTextMessage(this.help)
    }
    if (this.placeholder !== undefined) {
      result.placeholder = saveTextMessage(this.placeholder)
    }
    if (this.description !== undefined) {
      result.description = saveTextMessage(this.description)
    }
    if (this._validator !== undefined) {
      result.validator = this._validator.save()
    }
    if (this.visibleEvaluator) {
      result.visible = this.visibleEvaluator.save()
    } else {
      if (!this.visible) {
        result.visible = this.visible
      }
    }
    if (this.requiredEvaluator) {
      result.required = this.requiredEvaluator.save()
    } else {
      if (!this.required) {
        result.required = this.required
      }
    }
    if (this.readonlyEvaluator) {
      result.readonly = this.readonlyEvaluator.save()
    } else {
      if (this.readonly) {
        result.readonly = this.readonly
      }
    }
    if (this.factEvaluator) {
      result.fact = this.factEvaluator.save()
    } else {
      if (this._fact?.raw) {
        result.fact = this._fact.save()
      }
    }
    return result
  }

  public get validator (): Validator {
    return this._validator ?? QuestionObject.DEFAULT_VALIDATOR
  }

  public set validator (validator: Validator) {
    this._validator = validator
  }

  public getValidator<T extends Validator> (): T | undefined {
    const result = this._validator ?? QuestionObject.DEFAULT_VALIDATOR
    return result as T
  }

  public validate (ctx: ExecutionContext): ValidationContext {
    if (this.validator === undefined) {
      return new ValidationContext(this.fact)
    }
    return this.validator.validate(ctx, this.fact)
  }

  public get fact (): Fact<T> {
    return this._fact
  }

  public set fact (fact: Fact<T>) {
    this._fact = fact
  }

  public setFactValue (val?: T | string, i18nContext?: I18nContext, timestamp?: number): void {
    if (this._fact) {
      this._fact.setValue(val, i18nContext, timestamp)
    } else {
      this._fact = new Fact<T>()
      if (val) {
        this._fact.setValue(val, i18nContext, timestamp)
      }
    }
  }
}
