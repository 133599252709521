import React, { useContext } from 'react'
import { EuiForm, EuiPanel, EuiTitle } from '@elastic/eui'
import { AnketaContext, EvaluationContext } from '../context_provider'

export interface CatalogCardProps {
  showTitle?: boolean
}

export const CatalogCard = ({ showTitle }: CatalogCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext)
  const evalCtx = useContext(EvaluationContext)
  let title
  if (showTitle !== false) {
    title = (<EuiTitle>
          <h3>{ctx.i18nContext.render(ctx.catalog.title)}</h3>
        </EuiTitle>)
  }
  return (
    <EuiPanel
      hasBorder={true}
      grow={true}
      color='subdued'>
        {title}
        <EuiForm component="form" className="markDownContainer">
          {ctx.childrenForItem(ctx.catalog, evalCtx.focusItem)}
        </EuiForm>
      </EuiPanel>
  )
}
